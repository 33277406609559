@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Black-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Black-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Black-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Black-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Black-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Black-Alphabetic.svg#NotoSansKR-Black-Alphabetic") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Thin-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Thin-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Thin-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Thin-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Thin-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Thin-Alphabetic.svg#NotoSansKR-Thin-Alphabetic") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Regular-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Regular-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Regular-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Regular-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Regular-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Regular-Alphabetic.svg#NotoSansKR-Regular-Alphabetic") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Bold-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Bold-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Bold-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Bold-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Bold-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Bold-Alphabetic.svg#NotoSansKR-Bold-Alphabetic") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Medium-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Medium-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Medium-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Medium-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Medium-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Medium-Alphabetic.svg#NotoSansKR-Medium-Alphabetic") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Light-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Light-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Light-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Light-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Light-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Light-Alphabetic.svg#NotoSansKR-Light-Alphabetic") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "PlusJakartaSans";
    src: url("../fonts/PlusJakartaSans-Regular.eot");
    src: url("../fonts/PlusJakartaSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PlusJakartaSans-Regular.woff2") format("woff2"),
        url("../fonts/PlusJakartaSans-Regular.woff") format("woff"), url("../fonts/PlusJakartaSans-Regular.ttf") format("truetype"),
        url("../fonts/PlusJakartaSans-Regular.svg#PlusJakartaSans-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Bold.eot");
    src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff2") format("woff2"),
        url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype"),
        url("../fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-bRg.eot");
    src: url("../fonts/NanumSquareNeoTTF-bRg.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-bRg.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-bRg.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-bRg.ttf") format("truetype"),
        url("../fonts/NanumSquareNeoTTF-bRg.svg#NanumSquareNeoTTF-bRg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-aLt.eot");
    src: url("../fonts/NanumSquareNeoTTF-aLt.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-aLt.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-aLt.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-aLt.ttf") format("truetype"),
        url("../fonts/NanumSquareNeoTTF-aLt.svg#NanumSquareNeoTTF-aLt") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-cBd.eot");
    src: url("../fonts/NanumSquareNeoTTF-cBd.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-cBd.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-cBd.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-cBd.ttf") format("truetype"),
        url("../fonts/NanumSquareNeoTTF-cBd.svg#NanumSquareNeoTTF-cBd") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-dEb.eot");
    src: url("../fonts/NanumSquareNeoTTF-dEb.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-dEb.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-dEb.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-dEb.ttf") format("truetype"),
        url("../fonts/NanumSquareNeoTTF-dEb.svg#NanumSquareNeoTTF-dEb") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-eHv.eot");
    src: url("../fonts/NanumSquareNeoTTF-eHv.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-eHv.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-eHv.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-eHv.svg#NanumSquareNeoTTF-eHv") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
body,
html,
p,
a,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
input,
textarea,
select,
option,
td,
th,
tr,
button,
ul,
li {
    font-family: "NanumSquare Neo" !important;
    color: #000000;
    text-decoration: none;
}

img {
    max-width: 100%;
}

textarea {
    resize: none;
    font-family: "NanumSquare Neo";
}

.color_blue {
    color: #277ae9;
}

.msg_time .time {
    position: absolute;
    right: 0;
    bottom: -40px;
    color: #ff6666;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox 
input[type=number] { 
  -moz-appearance: textfield;
}*/
.text-16 {
    font-size: 16px !important;
    font-weight: normal !important;
}

.hr_line {
    width: 100%;
    height: 95px;
    border-bottom: 2px solid transparent;
    -o-border-image: linear-gradient(0.25turn, rgba(243, 243, 243, 0.5), rgb(243, 243, 243), rgba(243, 243, 243, 0.4));
    border-image: linear-gradient(0.25turn, rgba(243, 243, 243, 0.5), rgb(243, 243, 243), rgba(243, 243, 243, 0.4));
    border-image-slice: 1;
}

.form-control:focus {
    border-color: #ebebeb !important;
    box-shadow: none;
}

.padding_0 {
    padding: 0;
}

.mtb190 {
    margin-top: 50px;
    margin-bottom: 190px;
}

.col-form-label,
.form-label {
    font-size: 16px;
    padding: 15px 0 5px;
    font-weight: 700;
    color: #333333;
    line-height: 26px;
    margin: 0;
}

.form-check-input[type="radio"] {
    height: 28px;
    width: 28px;
    border: 8px solid #efefef;
}

.form-check-input[type="radio"]:checked {
    border: 2px solid #277ae9;
}

.form-control,
.form-select,
.input-group-text,
.edit_save_btn > button,
.user_modal button {
    font-size: 16px;
    padding: 15px;
    font-weight: normal;
    line-height: 26px;
    color: #000;
    border-color: #ebebeb;
}

.required_field,
.text-danger {
    color: #ff6666 !important;
}

.form-check-input[type="checkbox"] {
    border-radius: 100%;
    padding: 0;
    height: 28px;
    width: 28px;
    background: url("../images/check_box_icon.png") no-repeat center left;
    border: none;
}

.form-check-input[type="checkbox"]:checked {
    background: url("../images/check_box_icon_chk.png") no-repeat center left;
}

.form-check-label {
    line-height: initial;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: normal;
    color: #777777;
}

.input-group-text,
.input-group-text:hover,
.btn_layout.un_active,
.btn_layout.un_active:hover,
.edit_save_btn > button,
.edit_save_btn > button:hover,
.user_modal button,
.user_modal button:hover,
.btn-primary.un_active {
    color: #ffffff;
    background: #cbcbcb;
    border-color: #cbcbcb;
}

.input-group-text.active,
.input-group-text.active:hover,
.btn_layout.active,
.edit_save_btn > button.save_btn1,
.btn-primary.active {
    background-color: #277ae9;
    border-color: #277ae9;
}

.multi_check_box .form-check-input {
    display: contents;
}
.multi_check_box .form-check-label {
    margin-left: -25px;
    border: solid 1px #ebebeb;
    border-radius: 30px;
    padding: 10px 22px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #333333;
}
.multi_check_box .form-check-input:checked + .form-check-label {
    background-color: #277ae9;
    border-color: #277ae9;
    color: #ffffff;
}

.btn_layout {
    background-color: #277ae9;
    border-radius: 100px;
    color: #ffffff;
    padding: 20px 120px;
    line-height: initial;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    border: solid 1px #277ae9;
    font-size: 18px;
    width: auto;
    margin: 0 auto;
}

.btn_layout:hover {
    background-color: #277ae9;
    border: solid 1px #277ae9;
    color: #ffffff;
}

.width_100 {
    width: 100%;
}

.heading {
    font-size: 44px;
    color: #000000;
    margin: 0 0 100px;
    font-weight: 700;
    line-height: 70px;
}

.inner_heading {
    font-size: 48px;
    color: #000;
    font-weight: 700;
    margin: 0 0 20px;
    text-align: center;
}

.mleft10 {
    margin-left: 10px;
}

.nav-link:hover {
    color: #277ae9;
}

.nav-link.custom_btn:focus,
.nav-link.custom_btn:hover {
    color: #ffffff;
}

.mt100 {
    margin-top: 50px;
}

.mb100 {
    margin-bottom: 100px;
}

.notice {
    border: solid 1px #ccc;
    padding: 30px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: normal;
}

.not_fixed {
    background: transparent;
    position: absolute;
    width: 100%;
    box-shadow: none;
    padding: 0px 0;
    top: 0;
}

.main_header {
    padding: 20px 0;
    z-index: 999;
    position: relative;
}
.main_header .navbar-collapse .navbar-nav {
    margin-top: 5px;
}

.header {
    background-color: #ffffff;
    box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.3);
    animation: slideDown 0.35s ease-out;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 0px 0;
}

.header_logo {
    width: 20%;
}
.header_logo img {
    width: 100%;
    height: auto;
}

.menu_bar ul li a {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    position: relative;
    padding-bottom: 0;
}
.menu_bar ul li button.btn_layout {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 25px;
}
.menu_bar ul .after_login {
    width: 30%;
    text-align: center;
    margin-top: -10px;
}
.menu_bar ul .after_login a {
    color: #000;
    font-weight: normal;
    font-size: 16px;
}
.menu_bar ul .after_login a span.user_name {
    font-weight: bold;
    font-size: 20px;
}
.menu_bar ul .after_login a span.bran_name {
    color: #277ae9;
    font-weight: 700;
}
.menu_bar ul .after_login .dropdown-toggle::after {
    display: none;
}
.menu_bar ul .after_login .dropdown-menu {
    box-shadow: 0 0px 8px 0px rgba(0, 0, 0, 0.2);
    border: none;
    padding: 20px 0;
    margin: 15px 0 0;
    border-radius: 10px;
}
.menu_bar ul .after_login .dropdown-menu li {
    padding: 0;
}
.menu_bar ul .after_login .dropdown-menu li a {
    line-height: initial;
    padding: 10px 5px;
    font-size: 14px;
    color: #777777;
    font-weight: bold;
}
.menu_bar ul .after_login .dropdown-menu li a:hover {
    background-color: #277ae9;
    color: #ffffff;
}
.menu_bar ul .after_login .dropdown-menu li:nth-last-child(2) {
    margin-bottom: 15px;
}
.menu_bar ul .after_login .dropdown-menu li.nav-item a {
    width: auto;
    border-top: solid 1px #dfe1e8;
    background: #ffffff;
    color: #999999;
    padding: 25px 20px 15px;
}
.menu_bar ul .after_login .dropdown-menu li.nav-item a:hover {
    color: #277ae9;
}

.menu_bar ul li:first-child a::after {
    content: "|";
    padding-left: 15px;
}

.menu_bar ul .after_login .dropdown-menu a::after {
    display: none;
}

.landing_page .hm_banner .banner_body {
    align-items: center;
}
.landing_page .hm_banner .banner_body .banner_text {
    text-align: left;
    margin-bottom: 0px;
}
.landing_page .hm_banner .banner_body .banner_text h1 {
    font-size: 70px;
}
.landing_page .hm_banner .banner_body .banner_text .row {
    width: 36%;
    margin-top: -100px;
    margin-left: 3%;
}
.landing_page .hm_banner .banner_body .banner_text .row .bar_code {
    margin-bottom: 40px;
    text-align: right;
}
.landing_page .hm_banner .banner_body .banner_text .row .bar_code img {
    width: 80%;
}
.landing_page .hm_banner .banner_body .banner_text .row .app_btns a {
    margin: 0 0 30px;
    float: left;
}

.hm_banner {
    width: 100%;
    position: relative;
}
.hm_banner img {
    width: 100%;
}
.hm_banner .floding_effect {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
}
.hm_banner .banner_body {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: end;
}
.hm_banner .banner_body .banner_text {
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
}
.hm_banner .banner_body .banner_text h1 {
    margin-bottom: 40px;
    font-size: 60px;
    font-weight: 700;
    color: #000000;
}
.hm_banner .banner_body .banner_text a.btn_layout {
    font-size: 20px;
}

.benefit_pts {
    width: 100%;
    padding: 30px 0 200px;
}
.benefit_pts .benefit_pts_dtls:last-child,
.benefit_pts .benefit_pts_dtls:nth-child(4),
.benefit_pts .benefit_pts_dtls:first-child {
    margin-top: 100px;
}
.benefit_pts .benefit_pts_dtls:nth-child(3) {
    margin-top: 30px;
}
.benefit_pts .benefit_pts_dtls:nth-child(4) .text_info,
.benefit_pts .benefit_pts_dtls:nth-child(5) .text_info {
    margin-top: 60px;
}
.benefit_pts .benefit_pts_dtls {
    margin-top: 0px;
}
.benefit_pts .benefit_pts_dtls .text_info {
    margin-top: 140px;
}
.benefit_pts .benefit_pts_dtls p {
    font-size: 24px;
    color: #277ae9;
    margin: 0 0 30px;
    font-weight: 600;
}
.benefit_pts .benefit_pts_dtls h1.fontsize_40 {
    font-size: 40px;
}
.benefit_pts .benefit_pts_dtls h1 {
    font-size: 50px;
    font-weight: 700;
    color: #000000;
    position: relative;
    line-height: 70px;
}
.benefit_pts .benefit_pts_dtls h1 img.icon_img {
    padding: 0;
    width: 130px;
    position: absolute;
    bottom: -40px;
    left: 31%;
    z-index: -1;
}
.benefit_pts .benefit_pts_dtls h1 img.icon_img2 {
    left: 145px;
    bottom: -90px;
}
.benefit_pts .benefit_pts_dtls h1 img.icon_img3 {
    left: 245px;
    top: 0;
}
.benefit_pts .benefit_pts_dtls h1 img.icon_img4 {
    left: 111px;
    bottom: -35px;
}
.benefit_pts .benefit_pts_dtls h1 img.icon_img5 {
    left: 146px;
    top: -100px;
}
.benefit_pts .benefit_pts_dtls div {
    padding: 0 20px 20px;
}
.benefit_pts .benefit_pts_dtls img {
    width: 90%;
    float: left;
}
.benefit_pts .benefit_pts_dtls .float_right {
    float: right;
}

.campaign_process {
    background-color: #f7f8fa;
    padding: 150px 0;
}
.campaign_process .campaign_process_pts .number {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 65px;
}
.campaign_process .campaign_process_pts .number span {
    border-top: solid 2px #000000;
    padding-top: 12px;
}
.campaign_process .campaign_process_pts img {
    max-width: 100%;
    width: auto;
}
.campaign_process .campaign_process_pts h4 {
    font-size: 22px;
    font-weight: 700;
    margin: 55px 0 30px;
}
.campaign_process .campaign_process_pts p {
    font-size: 16px;
    color: #777777;
    font-weight: normal;
    line-height: 24px;
}
.campaign_process .campaign_process_pts .card {
    border: none;
    background: transparent;
}

footer {
    background: #303041;
    width: 100%;
    float: left;
    padding: 80px 0;
}
footer .footer_logo {
    margin: 0 0 30px;
}
footer .footer_logo img {
    max-width: 100%;
    width: 15%;
}
footer p,
footer a {
    font-size: 15px;
    color: #acacb6;
    line-height: 24px;
    margin: 0;
}
footer p span {
    color: #acacb6;
}
footer h5 {
    font-size: 18px;
    font-weight: bold;
    color: #acacb6;
}
footer ul.term_policy {
    margin-top: 30px;
    text-align: left;
}
footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right;
}
footer ul li {
    display: inline-block;
}
footer ul li a {
    color: #acacb6;
    padding: 0 13px;
    text-decoration: none;
    position: relative;
    font-size: 15px;
}
footer ul li a:hover {
    color: #acacb6;
}
footer .mail_btn,
footer .mail_btn:hover,
footer .mail_btn:active {
    background: transparent;
    border: none;
    padding: 0;
    color: #acacb6;
    font-size: 16px;
    line-height: 30px;
}
footer .position_relative {
    position: relative;
}
footer .position_relative .success-msg {
    position: absolute;
    top: 30px;
    background: #ffffff;
    color: #000;
    padding: 6px 10px;
    z-index: 9;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
}

footer ul.term_policy li a:after {
    position: absolute;
    content: "|";
    padding-left: 13px;
}

footer ul.term_policy li:last-child a:after {
    display: none;
}

footer ul li:first-child a {
    padding-left: 0;
}

footer ul li:last-child a {
    padding-right: 0;
}

.login_modal .modal-content {
    border-radius: 0;
}
.login_modal .modal-body {
    padding: 80px 90px;
}
.login_modal .popup_logo {
    width: 100%;
    margin: 0 0 50px;
    text-align: center;
}
.login_modal .popup_logo img {
    width: 50%;
}
.login_modal input {
    padding: 20px;
    line-height: initial;
    font-size: 16px;
    font-weight: 700;
    border-color: #ebebeb;
    color: #bbbbbb;
    font-weight: normal;
}
.login_modal input[type="checkbox"] {
    border-radius: 100%;
    padding: 0;
    height: 28px;
    width: 28px;
}
.login_modal .form-check-label {
    line-height: initial;
    padding: 8px 10px;
    font-size: 16px;
    font-weight: normal;
    color: #777777;
}
.login_modal .btn_layout {
    padding: 22px;
    font-size: 18px;
    font-weight: 700;
}
.login_modal .find_pass_id {
    font-size: 14px;
    color: #777777;
    padding-bottom: 20px;
    border-bottom: solid 1px #ebebeb;
}
.login_modal .find_pass_id .text-end {
    border-right: solid 1px #ebebeb;
}
.login_modal .register_btn {
    /*.btn_layout:hover {
      background-color: $blue;
      color: $white;
  }
  */
}
.login_modal .register_btn .btn_layout {
    background-color: #ffffff;
    color: #277ae9;
}
.login_modal .register_btn P {
    font-size: 14px;
    color: #777777;
}

.register_form > .container,
.tab_panel > .container,
.common_layout .container {
    width: 45%;
}

.tab_panel > .container {
    width: 33%;
}

.register_form {
    width: 100%;
}
.register_form .term_condition {
    padding: 20px 20px 0;
    border: solid 1px #ebebeb;
    border-radius: 6px;
    margin: 5px 0 20px;
}
.register_form .term_condition .form-check div {
    margin: 0 0 15px;
}

.findresult_panel,
.findresult_panel_ad {
    width: 100%;
    margin: 0 0 30px;
}

.findresults {
    width: 100%;
    padding: 50px 80px;
    text-align: center;
    margin: 0 0 30px;
    /*background: $lightgray;*/
    border-radius: 10px;
    border: solid 3px #edf3fc;
}
.findresults h5 {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    margin: 0 0 30px;
}
.findresults p {
    font-size: 18px;
    color: #000;
    margin: 0;
}
.findresults .btn_layout {
    margin: 50px 0 0;
}
.findresults .findresults_show {
    padding: 10px 0 0;
}
.findresults .findresults_show p {
    padding: 0;
}
.findresults .findresults_show p span.left {
    margin-right: 10px;
}
.findresults .findresults_show p span.right {
    margin-left: 10px;
}

.tab_panel .nav-fill {
    border-bottom: solid 1px #cbcbcb;
}
.tab_panel .nav-fill .nav-item .nav-link {
    width: 100%;
    border-bottom: solid 0px #cbcbcb;
    border-top: none;
    border-left: none;
    border-right: none;
    color: #777777;
    font-weight: normal;
    font-size: 18px;
    color: #777777;
}
.tab_panel .nav-fill .nav-item .nav-link.active {
    border-bottom: solid 4px #277ae9;
    color: #277ae9;
    font-weight: bold;
}
.tab_panel .tab-content {
    padding: 20px 0;
}
.tab_panel .tab-content .input-group-text {
    cursor: pointer;
    margin-left: 10px !important;
    border-radius: 4px !important;
    width: 150px;
}
.tab_panel .tab-content .input-group input.form-control {
    border-radius: 8px !important;
}
.tab_panel .tab-content .register_form > .container {
    width: 100%;
}

.userinfo_data .input-group .edit_save_btn {
    margin-left: 10px !important;
    border-radius: 4px !important;
}

.userinfo_data .input-group .edit_save_btn button {
    position: unset;
    width: 100%;
}

.delete_account_btn button,
.delete_account_btn button:hover {
    margin: 50px 0;
    background: transparent;
    border: none;
    border-bottom: solid 1px #777777;
    border-radius: 0;
    padding: 0;
    color: #777777;
    font-size: 16px;
    font-weight: 700;
}

.user_modal .modal-content {
    padding: 70px;
}
.user_modal .modal-header {
    border: none;
    padding: 0;
}
.user_modal .modal-header h5 {
    width: 100%;
    margin: 0px 0 30px;
    font-weight: 700;
    font-size: 36px;
    color: #000;
}
.user_modal .modal-body .notice {
    border: solid 1px #ccc;
    padding: 30px;
    border-radius: 10px;
}
.user_modal .save_btn1 {
    margin: 20px 0;
}

.faq_panel .nav-tabs {
    width: auto;
    display: inline-block;
    border: none;
}
.faq_panel .nav-tabs .nav-item {
    text-align: center;
    float: left;
    margin-right: 10px;
}
.faq_panel .nav-tabs .nav-item .nav-link {
    padding: 20px 80px;
    border: solid 1px #277ae9;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 700;
    color: #277ae9;
}
.faq_panel .nav-tabs .nav-item .nav-link.active {
    background: #277ae9;
    color: #ffffff;
    border: solid 1px #277ae9;
}
.faq_panel .accordion .accordion-header {
    padding: 20px 0;
}
.faq_panel .accordion .accordion-item:last-child .accordion-header {
    border: none;
}
.faq_panel .accordion button {
    left: 0;
    top: 0;
    border: none;
    background-color: transparent;
    justify-content: left;
    padding: 0 0px;
    font-size: 18px;
    font-weight: 700;
    color: #333333;
}
.faq_panel .accordion .accordion-button::after {
    display: none;
}
.faq_panel .accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
    background-color: #ffffff;
}
.faq_panel .accordion .accordion-collapse.collapse.show {
    margin: 0 0 0px;
}
.faq_panel .accordion .accordion-button:focus {
    border: none;
    box-shadow: none;
}
.faq_panel .accordion .accordion-body {
    font-size: 16px;
    font-weight: normal;
    color: #333;
    padding: 0 0 20px;
}
.faq_panel .accordion .accordion-item {
    border: none;
    background: transparent;
    border-bottom: solid 1px #f7f7f7;
}
.faq_panel .faq_details {
    width: 100%;
    margin: 0 0 70px;
}
.faq_panel .faq_details h3 {
    font-weight: bold;
    font-size: 24px;
    color: #000;
    margin: 0 0 10px;
    border-bottom: solid 1px #f7f7f7;
    background: #edf3fc;
    padding: 10px 20px;
    line-height: initial;
}
.faq_panel .faq_details .accordion-item {
    padding-left: 40px;
}

.contactus_data .col-sm-2.form-label,
.contactus_data .col-sm-1.form-label {
    padding-left: 20px;
}

#contactusModal .modal-body p {
    font-size: 18px;
}

#contactusModal .modal-body button {
    width: auto;
    padding: 20px 150px;
    font-size: 18px;
    font-weight: bold;
    line-height: initial;
}

.settlement_dtls table {
    border-spacing: 10px;
    border-collapse: separate;
    border-radius: 5px;
}
.settlement_dtls table th,
.settlement_dtls table td {
    border-radius: 5px;
    padding: 20px 10px;
    font-size: 16px;
}
.settlement_dtls table th {
    border: solid 1px #277ae9;
    color: #277ae9;
    font-weight: 700;
}
.settlement_dtls table td {
    border: solid 1px #ebebeb;
    color: #333;
}
.settlement_dtls table td:nth-child(5) {
    width: 300px;
}
.settlement_dtls .table-striped > tbody > tr:nth-of-type(even) > * {
    background-color: #f7f7f7;
}
.settlement_dtls .table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: #ffffff;
    --bs-table-accent-bg: $white;
}
.settlement_dtls .page-link {
    border: none;
    border: none;
    font-size: 16px;
    padding: 8px 14px;
    line-height: initial;
    color: #666666;
    border-radius: 100% !important;
    margin: 0 10px;
}
.settlement_dtls .active > .page-link,
.settlement_dtls .page-link.active {
    border-radius: 100%;
    color: #ffffff;
}

.mycampaign_detail p.simple_text {
    font-size: 18px;
    font-weight: 700;
    color: #000;
}
.mycampaign_detail .mycampaign_detailinner {
    background-color: #f7f7f7;
    width: 100%;
    text-align: center;
    border: none;
    border-radius: 20px;
    height: 300px;
}
.mycampaign_detail .mycampaign_detailinner .custom_radio {
    height: 28px;
    width: 28px;
    border: solid 1px #277ae9;
    display: inline-block;
    margin: 50px auto 20px;
}
.mycampaign_detail .mycampaign_detailinner .custom_radio:checked {
    background-image: url("../images/radio_icon.png");
    background-size: 14px auto;
    background-color: #f7f7f7;
}
.mycampaign_detail .mycampaign_detailinner .custom_label {
    padding: 0 50px;
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
}
.mycampaign_detail .mycampaign_detailinner .custom_label > div {
    padding-top: 100px;
}
.mycampaign_detail .mycampaign_detailinner h5 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
}
.mycampaign_detail .mycampaign_detailinner p {
    font-size: 18px;
    margin: 0;
    padding: 0 0 70px;
    line-height: 24px;
    color: #333;
    line-height: 26px;
}
.mycampaign_detail .mycampaign_detailinner .custom_radio:checked + .custom_label > div p,
.mycampaign_detail .mycampaign_detailinner .custom_radio:checked + .custom_label h5 {
    color: #ffffff;
}

.review_small_width .container {
    width: 65%;
}
.review_small_width .review_category {
    margin-bottom: 40px;
}
.review_small_width .review_category p {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-bottom: 30px;
}
.review_small_width .review_category p span {
    color: #277ae9;
    font-size: 22px;
    border-bottom: solid 1px #277ae9;
    margin-right: 10px;
}
.review_small_width .review_category .nopeople {
    font-weight: normal;
    position: absolute;
    top: 4px;
    right: 10px;
    padding: 15px;
}
.review_small_width .processRecommended1,
.review_small_width .processProduct1 {
    position: relative;
}

.campaign_cost .container {
    width: 65%;
}
.campaign_cost p {
    margin: 0;
    font-size: 18px;
    color: #333333;
}
.campaign_cost .row {
    border-bottom: solid 1px #f7f7f7;
    padding: 30px;
}
.campaign_cost .row.fw-bolder {
    font-weight: bold;
    background: #f7f7f7;
    border-radius: 10px;
}
.campaign_cost .row.fw-bolder p {
    font-size: 24px;
    font-weight: bold;
}

.register_campaign_tab .nav-tabs .nav-link {
    border-radius: 0;
}
.register_campaign_tab .container {
    width: 100%;
}
.register_campaign_tab .tab-content .common_review {
    margin-top: 50px;
}
.register_campaign_tab .tab-content .common_review .container {
    width: 65%;
}
.register_campaign_tab .tab-content .common_review .container .mb-7 {
    margin-bottom: 60px;
}
.register_campaign_tab .tab-content .common_review .form-label {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-bottom: 15px;
}
.register_campaign_tab .tab-content .common_review .form-label span.numbers {
    color: #277ae9;
    font-size: 22px;
    border-bottom: solid 1px #277ae9;
    margin-right: 10px;
    font-weight: 700;
}
.register_campaign_tab .tab-content .common_review .gender_age .row {
    border: solid 1px #ccc;
    padding: 12px 0px;
    border-radius: 8px;
    margin: 0 0 10px;
}
.register_campaign_tab .tab-content .common_review .gender_age .row .width_10 {
    width: 10%;
}
.register_campaign_tab .tab-content .common_review .gender_age .row .width_90 {
    width: 90%;
}
.register_campaign_tab .tab-content .common_review .gender_age .row .form-label {
    margin: 0;
}
.register_campaign_tab .tab-content .common_review .form-text {
    font-size: 16px;
    color: #b2b2b2;
    margin-top: 8px;
}

.addFile {
    border: solid 1px #f7f7f7;
    margin: 0 0 5px;
    background: #277ae9;
    text-align: center;
    padding: 16px 50px;
    border-radius: 6px;
    font-size: 18px;
    cursor: pointer;
    color: #ffffff;
    font-weight: 700;
    line-height: initial;
}

.overlay_file1 .overlay_file {
    position: absolute;
    right: 0;
    width: 77%;
    bottom: 0;
    top: 0;
}

.tab-content .register_posting_guide label {
    padding: 8px 10px;
}

.basic_register_campaign .addFile {
    width: auto;
    float: left;
}
.basic_register_campaign .addFile_input {
    float: left;
    width: auto;
    margin-left: 5px;
}
.basic_register_campaign .gender_age .form-label {
    padding: 10px 0 0px;
}

input::file-selector-button {
    display: none;
}

.success_story {
    width: 100%;
    padding: 50px 0;
    text-align: center;
}
.success_story .success_story_pts {
    margin: 50px 0 0;
}
.success_story .success_story_pts img {
    border-radius: 10px;
}

.tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    top: 120%;
    left: 50%;
    margin-left: -60px;
    background-color: black;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.scroll__container {
    position: fixed;
    right: 24px;
    bottom: 95px;
    z-index: 1;
}
.scroll__container img {
    width: 56px;
}

#top {
    background: transparent;
    border: none;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
}

#top:hover {
    box-shadow: 0 4px 7px 0px rgba(0, 0, 0, 0.5);
}

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
    background-color: #555;
    color: white;
    padding: 16px 10px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 28px;
    width: 60px;
    border-radius: 100%;
    height: 60px;
    text-align: center;
}

/* The popup chat - hidden by default */
.chat-popup {
    display: block;
    position: fixed;
    bottom: 90px;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
}

/* Add styles to the form container */
.form-container {
    max-width: 300px;
    padding: 10px;
    background-color: white;
}

/* Full-width textarea */
.form-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
    background-color: #ddd;
    outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
    background-color: #04aa6d;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
    background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
    opacity: 1;
}

/* The popup chat - End css */
.influencer_channel > div {
    border: none;
    padding: 0;
}
.influencer_channel > div div {
    background-color: #ccc;
    padding: 10px 15px;
}
.influencer_channel > div div .form-check-input {
    margin-left: 0;
    margin-right: 10px;
}

.mycampaign {
    margin-bottom: 100px;
}

.h-75_custom {
    height: 200px;
}

.clander_input {
    width: 100%;
    float: left;
}
.clander_input input {
    width: auto;
    float: left;
    margin-right: 10px;
    padding: 20px 0px 20px 60px;
    border: solid 1px #ebebeb;
    background: url("../images/clander_icon.png") no-repeat center left 20px;
    background-color: #efefef;
}

.check_campaign_request .campaign_request_detail .container {
    width: 65%;
}
.check_campaign_request .campaign_request_detail .container .row {
    border-bottom: solid 1px #e6e6e6;
    padding: 20px 10px;
}
.check_campaign_request .campaign_request_detail .container .row h4 {
    font-size: 18px;
    color: #010101;
    font-weight: 700;
    margin: 0;
    line-height: initial;
}
.check_campaign_request .campaign_request_detail .container .row h4 span {
    color: #277ae9;
}
.check_campaign_request .campaign_request_detail .container .row p {
    font-size: 18px;
    color: #010101;
    font-weight: normal;
    margin: 0;
    line-height: initial;
}
.check_campaign_request .campaign_request_detail .container .row .campaign_period .row:first-child {
    padding-top: 0;
}
.check_campaign_request .campaign_request_detail .container .row .campaign_period .row:last-child {
    padding-bottom: 0;
    border: none;
}
.check_campaign_request .edit_campaignrq {
    margin: 50px 0 120px;
}

.campaign_pymt_done .container {
    width: 55%;
}

.edit_reeview .container {
    width: 70%;
}
.edit_reeview .container .form-label {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-bottom: 10px;
}
.edit_reeview .container .datepicker .form-label {
    margin-bottom: 30px;
}
.edit_reeview .clander_input input {
    width: 200px;
}

.nopeopless .col-sm-2 {
    position: relative;
}
.nopeopless .col-sm-2 .nopeople {
    position: absolute;
    right: 25px;
    top: 35%;
}

.mrt-15 {
    top: -35px;
}

.mycampaign .btn_layout {
    font-size: 24px;
}
.mycampaign .accordion button.btn_layout {
    background-color: #277ae9;
    border-radius: 100px;
    color: #ffffff;
    padding: 20px 120px;
    line-height: initial;
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    border: solid 1px #277ae9;
    font-size: 18px;
    width: auto;
    margin: 0 auto;
}
.mycampaign .nav-tabs .nav-item .nav-link {
    font-size: 18px;
}
.mycampaign .form-select {
    font-size: 18px;
}
.mycampaign .findresults h3 {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 20px;
}
.mycampaign .findresults p {
    font-size: 16px;
    color: #333333;
}
.mycampaign .ongoing_campaign .fixed_height {
    height: 20px;
    overflow: hidden;
    line-height: 22px;
}
.mycampaign .ongoing_campaign .fixed_height.recruite_btn {
    position: relative;
    top: 4px;
}
.mycampaign .ongoing_campaign .recruite_btn {
    background: #277ae9;
    text-align: center;
    border-radius: 30px;
    height: auto;
    padding: 5px 2px;
    line-height: initial;
    color: #fff;
}
.mycampaign .ongoing_campaign .recruite_btn a,
.mycampaign .ongoing_campaign .recruite_btn button {
    color: #fff !important;
    line-height: initial;
}
.mycampaign .ongoing_campaign table {
    width: 100%;
}
.mycampaign .ongoing_campaign table th {
    font-size: 16px;
    padding: 10px 20px;
    width: 120px;
}
.mycampaign .ongoing_campaign table .cursor td {
    cursor: pointer;
}
.mycampaign .ongoing_campaign table td {
    font-size: 16px;
    font-family: "NanumSquare Neo";
    font-weight: normal;
    padding: 0 20px 10px;
    width: 150px;
}
.mycampaign .ongoing_campaign table td:nth-child(3) {
    width: 200px;
}
.mycampaign .ongoing_campaign table td:nth-child(4) {
    width: 250px;
}
.mycampaign .ongoing_campaign .accordion a {
    color: #ff6666;
    font-weight: bold;
}
.mycampaign .ongoing_campaign .accordion .accordion-item {
    border-radius: 8px;
    margin-bottom: 15px;
    overflow: hidden;
}
.mycampaign .ongoing_campaign .accordion .accordion-item .accordion-header {
    background-color: #f7f7f7;
    padding: 0px;
    border-bottom: solid 1px #ebebeb;
    border-radius: 8px;
}
.mycampaign .ongoing_campaign .accordion .accordion-item .accordion-header .accordion-button {
    padding: 10px;
    border: solid 1px #277ae9;
    border-bottom-color: #ebebeb;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.mycampaign .ongoing_campaign .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    padding: 10px;
    border: solid 1px #ebebeb;
}
.mycampaign .ongoing_campaign .accordion .accordion-item .accordion-collapse {
    padding: 30px 20px;
}
.mycampaign .ongoing_campaign .accordion .accordion-item .accordion-collapse.collapse.show {
    border: solid 1px #277ae9;
    border-top: none;
    margin-top: -1px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.mycampaign .ongoing_campaign .accordion .accordion-item table thead {
    /* display: none; */
}
.mycampaign .ongoing_campaign .accordion .accordion-item:first-child table thead {
    display: contents;
}
.mycampaign .ongoing_campaign .accordion .accordion-button:not(.collapsed),
.mycampaign .ongoing_campaign .accordion .accordion-button.collapsed {
    background-color: #f7f7f7;
}
.mycampaign .ongoing_campaign .accordion .accordion-button::after {
    display: block;
    background-image: url("../images/expand_icon.png");
    content: "";
    position: absolute;
    right: 2%;
    top: 35%;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    transform: none;
    background-size: 28px;
}
.mycampaign .ongoing_campaign .accordion .accordion-button.collapsed::after {
    background-image: url("../images/collapse_icon.png");
}
.mycampaign .countdown {
    width: 100%;
    float: left;
    padding: 10px 20px;
}
.mycampaign .countdown .countdown_info {
    background: #ecf3fb;
    padding: 20px 10px;
    text-align: center;
    border-radius: 8px;
    height: 90px;
}
.mycampaign .countdown .countdown_info p {
    margin: 0;
    font-weight: normal;
    color: #333333;
    font-size: 16px;
}
.mycampaign .countdown .countdown_info p span {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}
.mycampaign .countdown .countdown_info p span.date_time_txt {
    display: inline-block;
    width: 35%;
    border-right: solid 1px #bad3f5;
    margin: 0;
    line-height: initial;
}
.mycampaign .countdown .countdown_info p span.date_time {
    display: inline-block;
    width: 65%;
    font-size: 36px;
    color: #277ae9;
    margin: 0;
    line-height: initial;
}
.mycampaign .completed_campaign table th,
.mycampaign .completed_campaign table td {
    text-align: center;
}
.mycampaign .completed_campaign table td:nth-child(1) {
    width: 200px;
}
.mycampaign .completed_campaign table td:nth-child(3) {
    width: 160px;
}
.mycampaign .completed_campaign table td:nth-child(4) {
    width: 200px;
}
.mycampaign .completed_campaign .countdown {
    padding: 10px 25px 0;
}
.mycampaign .completed_campaign .countdown > .row {
    border-bottom: solid 1px #ebebeb;
    padding-bottom: 25px;
}
.mycampaign .completed_campaign .countdown .countdown_heading {
    background-color: #93bcf4;
    padding: 35px 10px;
}
.mycampaign .completed_campaign .countdown .countdown_heading p {
    color: #ffffff;
}
.mycampaign .completed_campaign .graph {
    width: 100%;
    float: left;
    padding: 20px 30px;
}
.mycampaign .completed_campaign .graph img {
    width: 100%;
}
.mycampaign .completed_campaign .graph .graph_details {
    padding: 70px 0;
}
.mycampaign .completed_campaign .graph .graph_details p {
    font-size: 16px;
    color: #333333;
}
.mycampaign .completed_campaign .graph .graph_details p span {
    font-weight: 700;
}
.mycampaign .completed_campaign .graph .graph_details .average_data {
    background: #ecf3fb;
    text-align: center;
    padding: 20px;
    border-radius: 4px;
}
.mycampaign .completed_campaign .graph .graph_details .average_data p {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    line-height: initial;
}
.mycampaign .completed_campaign .graph .graph_details .average_data p span {
    display: block;
    font-size: 36px;
    font-weight: bold;
    margin: 5px 0 0;
    line-height: initial;
}
.mycampaign .completed_campaign .accordion .accordion-item .accordion-header .accordion-button {
    border-color: #ebebeb;
}
.mycampaign .completed_campaign .accordion .accordion-item .accordion-collapse.collapse.show {
    border-color: #ebebeb;
}
.mycampaign .completed_campaign .registered_post {
    padding: 0 30px;
    overflow: hidden;
    transition: height 0.3s ease-out;
    height: auto;
}
.mycampaign .completed_campaign .registered_post h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 20px;
}
.mycampaign .completed_campaign .registered_post table thead {
    background-color: #f7f7f7;
    border-top: solid 1px #e6e6e6;
}
.mycampaign .completed_campaign .registered_post table tr {
    border-bottom: solid 1px #e6e6e6;
}
.mycampaign .completed_campaign .registered_post table th {
    padding: 20px;
    font-family: "NanumSquare Neo";
    font-weight: 700;
}
.mycampaign .completed_campaign .registered_post table td {
    width: auto;
    padding: 15px 20px;
    font-family: "NanumSquare Neo";
}
.mycampaign .completed_campaign .registered_post table td p {
    margin: 0;
}
.mycampaign .completed_campaign .registered_post table td:nth-child(1),
.mycampaign .completed_campaign .registered_post table th:nth-child(1) {
    width: 100px;
}
.mycampaign .completed_campaign .registered_post table td:nth-child(2),
.mycampaign .completed_campaign .registered_post table th:nth-child(2) {
    width: 100px;
}
.mycampaign .completed_campaign .registered_post table td:nth-child(3) {
    width: 500px;
    vertical-align: middle;
    text-align: left;
}
.mycampaign .completed_campaign .registered_post table td:nth-child(4) {
    width: auto;
}
.mycampaign .completed_campaign .registered_post .best_view_like {
    position: relative;
    text-align: center;
}
.mycampaign .completed_campaign .registered_post .best_view_like img {
    width: 100%;
}
.mycampaign .completed_campaign .registered_post .best_view_like span {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #ffffff;
    padding: 8px 0;
    line-height: initial;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    font-family: "NanumSquare Neo";
}
.mycampaign .completed_campaign .registered_post .best_view_like.best_view span {
    background: rgba(116, 160, 251, 0.9);
}
.mycampaign .completed_campaign .registered_post .best_view_like.best_like span {
    background: rgba(241, 100, 96, 0.9);
}
.mycampaign .completed_campaign .registered_post.expand_list {
    height: 200px;
    overflow: hidden;
}
.mycampaign .dwnload_exl {
    background-color: #ffffff;
    border: solid 1px #6599ff;
    color: #6599ff;
    padding: 15px 20px 15px 50px;
    background: url("../images/download_exl_icon.png") no-repeat center left 20px;
    line-height: initial;
}

.dashboard .image-gallery-icon {
    display: none;
}
.dashboard .image-gallery-content .image-gallery-slide {
    opacity: 0;
    transition: opacity 450ms ease-out !important;
}
.dashboard .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: initial;
    border-radius: 10px;
    height: 525px;
    border: solid 1px #ebebeb;
}
.dashboard .image-gallery-content .image-gallery-slide.center {
    opacity: 1;
}
.dashboard .image-gallery-thumbnails-wrapper {
    margin: 10px 0 0;
}
.dashboard .image-gallery-thumbnails-wrapper .image-gallery-thumbnail {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid transparent;
    margin: 0 5px;
    width: 80px;
}
.dashboard .image-gallery-thumbnail.active,
.dashboard .image-gallery-thumbnail:focus,
.dashboard .image-gallery-thumbnails .image-gallery-thumbnail:hover {
    border: 1px solid #ebebeb;
}
.dashboard .post_recruite {
    display: block;
}
.dashboard .post_recruite button {
    background-color: #ffffff;
    border: solid 1px #277ae9;
    color: #ffffff;
    padding: 7px 30px;
    line-height: initial;
    font-size: 16px;
    border-radius: 40px;
    font-weight: bold;
    background-color: #277ae9;
}
.dashboard .post button {
    background-color: #277ae9;
    color: #ffffff;
}
.dashboard h3 {
    font-size: 34px;
    font-weight: 700;
    margin: 0 0 20px;
}
.dashboard h3 span {
    display: block;
    font-size: 16px;
    margin-bottom: 15px;
}
.dashboard p {
    font-size: 16px;
    color: #666666;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
}
.dashboard .product_info {
    width: 100%;
    margin: 40px 0;
    padding: 40px 0 0;
    border-top: solid 1px #ebebeb;
}
.dashboard .product_info p {
    font-size: 16px;
    margin: 0 0 20px;
    color: #666666;
    font-weight: normal;
}
.dashboard .product_info p span {
    font-weight: 700;
    color: #000000;
}
.dashboard .dashbrd_lwrinfo {
    width: 100%;
    margin: 80px 0 50px;
}
.dashboard .dashbrd_lwrinfo .dashbrd_lwrinfo_detail {
    border: solid 1px #ebebeb;
    border-radius: 8px;
    height: 100%;
}
.dashboard .dashbrd_lwrinfo .dashbrd_lwrinfo_detail h4 {
    width: 100%;
    background-color: #f7f7f7;
    padding: 22px 30px;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    border-bottom: solid 1px #ebebeb;
}
.dashboard .dashbrd_lwrinfo .dashbrd_lwrinfo_detail ul {
    margin: 0;
    padding: 30px;
    padding-left: 50px;
}
.dashboard .dashbrd_lwrinfo .dashbrd_lwrinfo_detail ul li {
    list-style-type: none;
    color: #666666;
    font-size: 16px;
    font-weight: normal;
    line-height: 28px;
    word-wrap: break-word;
}

.padding_left_30 {
    padding-left: 45px;
}

.influencer .nav-tabs {
    border-bottom: solid 1px #000;
}
.influencer .nav-tabs .nav-item {
    border-bottom-color: #000;
}
.influencer .nav-tabs .nav-item .nav-link {
    background: #f7f7f7;
    padding: 8px 10px;
    border: none;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}
.influencer .nav-tabs .nav-item .nav-link .innertitle {
    font-size: 18px;
    color: #b2b2b2;
    font-weight: 700;
    text-align: center;
}
.influencer .nav-tabs .nav-item .nav-link .innertitle span {
    display: block;
}
.influencer .nav-tabs .nav-item .nav-link .innertitle span span {
    display: inline-block;
    border: solid 1px #ffffff;
    border-radius: 100%;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 10px;
    width: 25px;
    height: 25px;
    line-height: 27px;
    color: #b2b2b2;
    background-color: #ffffff;
}
.influencer .nav-tabs .nav-item .nav-link.active {
    border: solid 1px #000;
    border-bottom: none;
    background-color: #ffffff;
}
.influencer .nav-tabs .nav-item .nav-link.active .innertitle {
    color: #000;
}
.influencer .nav-tabs .nav-item .nav-link.active .innertitle span span {
    border-color: #000;
    color: #000;
}
.influencer .tab-content {
    padding: 50px 0 0;
}
.influencer .recuritstatus_box {
    background-color: #f4f6f9;
    border: solid 1px #ebebeb;
    border-radius: 8px;
    text-align: center;
    padding: 30px 30px 20px;
    margin: 50px 0 0px;
    position: relative;
}
.influencer .recuritstatus_box img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
.influencer .recuritstatus_box h3 {
    margin: 10px 0 5px;
    font-size: 24px;
    font-weight: normal;
    color: #333333;
}
.influencer .recuritstatus_box p {
    color: #777777;
    margin: 0px 0 5px;
    font-size: 14px;
    font-weight: normal;
}
.influencer .recuritstatus_box .btn_iconss {
    text-align: center;
    width: 100%;
    margin: 0 0 22px;
}
.influencer .recuritstatus_box .btn_iconss > div {
    width: auto;
    display: inline-block;
    background: #277ae9;
    margin: 0 4px;
    padding: 10px 22px;
    border-radius: 30px;
    color: #ffffff;
    line-height: initial;
    font-size: 18px;
    font-family: "NanumSquare Neo";
    font-weight: 700;
}
.influencer .recuritstatus_box .btn_iconss .select_confmd {
    background-color: #ff6666;
    display: none;
}
.influencer .recuritstatus_box .recuritstatus_detailinfo {
    text-align: left;
}
.influencer .recuritstatus_box .recuritstatus_detailinfo p {
    font-size: 16px;
    color: #777777;
    overflow: hidden;
    height: 20px;
}
.influencer .recuritstatus_box .recuritstatus_detailinfo p span {
    color: #333333;
    font-weight: 600;
}
.influencer .recuritstatus_box .btn_layout2.bg_grey {
    display: none;
}
.influencer .influe_selected {
    border: solid 2px #66a0ee;
}
.influencer .influe_selected .btn_iconss .select_confmd {
    display: inline-block;
}
.influencer .influe_selected .btn_layout2.bg_skyblue,
.influencer .influe_selected .btn_layout2.bg_skyblue:hover {
    background: #277ae9;
    border-color: #277ae9;
    display: none;
}
.influencer .influe_selected .btn_layout2.bg_grey,
.influencer .influe_selected .btn_layout2.bg_grey:hover {
    background: #cbcbcb;
    border-color: #cbcbcb;
    display: inline-block;
    color: #ffffff;
}
.influencer .posting_status .modal-body {
    padding: 70px 20px;
}
.influencer .posting_status p {
    font-size: 18px;
    color: #333333;
    margin: 0 0 50px;
    font-weight: normal;
}
.influencer .posting_status p span {
    font-weight: bold;
}
.influencer .posting_status button {
    margin: 0 5px;
    padding: 20px;
    width: 200px;
}

.product_shipping table tr td:nth-child(7),
.product_shipping table tr td:nth-child(6) {
    padding: 0;
}
.product_shipping table tr td input,
.product_shipping table tr td select {
    border-radius: 5px;
    padding: 20px 10px;
    font-size: 16px;
    border: none;
    text-align: center;
}
.product_shipping table tr td select {
    background: url("../images/select_option_icon.png") no-repeat center right 15px;
    text-align: left;
}

.influ_posting_status {
    border: solid 1px #cccccc;
    padding: 20px;
    border-radius: 8px;
    margin: 0 0 50px;
}
.influ_posting_status .date {
    text-align: right;
    margin: 0 0 5px;
    font-size: 12px;
    font-weight: 300;
    color: #777777;
}
.influ_posting_status .insta_user_info {
    margin: 0 0 20px;
}
.influ_posting_status .insta_user_info img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
.influ_posting_status .insta_user_info > div {
    width: 60%;
    float: right;
    padding: 30px 5px;
}
.influ_posting_status .insta_user_info > div h3 {
    font-size: 20px;
    color: #333333;
    font-weight: 700;
    margin: 0;
}
.influ_posting_status .insta_user_info > div p {
    font-size: 12px;
    color: #777777;
    font-weight: normal;
    margin: 0;
}
.influ_posting_status .influ_posting_like_comt ul {
    float: left;
}
.influ_posting_status .recuritstatus_detailinfo .btn_evalute {
    background: #89b0fd;
    color: #ffffff;
}
.influ_posting_status .recuritstatus_detailinfo .btn_retrival_complt {
    background: #ffffff;
    color: #777777;
    border-color: #777777;
}
.influ_posting_status .recuritstatus_detailinfo .btn_assmt_complt {
    background: #777777;
    color: #ffffff;
    border-color: #777777;
}
.influ_posting_status .recuritstatus_detailinfo .btn_delivery_complt {
    color: #277ae9;
    border-color: #277ae9;
}

.influ_posting_like_comt img {
    width: 100%;
}
.influ_posting_like_comt ul {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: right;
}
.influ_posting_like_comt ul li {
    display: inline-block;
    padding: 20px 0px 20px 30px;
    margin-left: 16px;
    line-height: initial;
}
.influ_posting_like_comt ul li:first-child {
    background: url("../images/heart_icon.png") no-repeat center left;
}
.influ_posting_like_comt ul li:last-child {
    background: url("../images/comment_icon.png") no-repeat center left;
}

.influencer_profile h6 {
    font-size: 18px;
    color: #000;
    font-weight: bold;
    margin: 0 0 20px;
    line-height: initial;
}
.influencer_profile .comn_profl_lyout {
    border: solid 1px #ebebeb;
    border-radius: 20px;
    padding: 30px;
}
.influencer_profile .comn_profl_lyout p {
    font-size: 16px;
    color: #777777;
    font-weight: normal;
    margin-bottom: 10px;
}
.influencer_profile .comn_profl_lyout p span {
    font-weight: 700;
    color: #333333;
}
.influencer_profile .ai_box {
    background-color: #efefef;
    padding: 16px 10px;
    text-align: center;
    border-radius: 14px;
}
.influencer_profile .ai_box p {
    margin: 0;
    font-weight: normal;
    color: #333333;
}
.influencer_profile .ai_box p span {
    color: #277ae9;
    font-weight: 700;
}
.influencer_profile .influ_prfl_box {
    margin: 0 0 80px;
}
.influencer_profile .influ_prfl_box1 .user_pic {
    text-align: center;
    margin-bottom: 30px;
}
.influencer_profile .influ_prfl_box1 .user_pic img {
    width: 110px;
    height: 110px;
    border-radius: 100%;
}
.influencer_profile .influ_prfl_box1 .ai_graph {
    padding: 85px 60px;
    position: relative;
}
.influencer_profile .influ_prfl_box1 .ai_graph img {
    width: 100%;
}
.influencer_profile .influ_prfl_box1 .ai_graph .radarchart_percentage {
    position: absolute;
    right: 10%;
    text-align: center;
    top: 40%;
}
.influencer_profile .influ_prfl_box1 .ai_graph .radarchart_percentage p {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin: 0 0px;
}
.influencer_profile .influ_prfl_box1 .ai_graph .radarchart_percentage h4 {
    color: #277ae9;
    font-size: 46px;
    font-weight: 900;
}
.influencer_profile .influ_prfl_box2 .comn_profl_lyout {
    padding: 30px 15px;
}
.influencer_profile .influ_prfl_box2 .comn_profl_lyout img {
    width: 80%;
    margin: 0 0 24px;
}
.influencer_profile .influ_prfl_box2 .comn_profl_lyout p {
    margin: 0;
}
.influencer_profile .influ_prfl_box2 .business_account {
    position: relative;
    border-radius: 20px;
    padding: 10px 0;
    border: solid 1px #ebebeb;
    text-align: center;
}
.influencer_profile .influ_prfl_box2 .business_account .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(229, 229, 229, 0.8);
    text-align: center;
    border-radius: 20px;
    overflow: hidden;
    padding: 95px 0;
}
.influencer_profile .influ_prfl_box2 .business_account .overlay h1 {
    font-size: 60px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
}
.influencer_profile .influ_prfl_box2 .business_account .row > div:last-child h1 {
    border: none;
}
.influencer_profile .influ_prfl_box2 .business_account .comn_profl_lyout {
    border: none;
}
.influencer_profile .influ_prfl_box2 .business_account .comn_profl_lyout h4 {
    font-size: 16px;
    color: #333;
    font-weight: 700;
    margin: 0;
}
.influencer_profile .influ_prfl_box2 .business_account .comn_profl_lyout h1 {
    border-right: solid 1px #ebebeb;
    font-size: 60px;
    font-weight: bold;
    color: #277ae9;
    padding: 36px 0 21px;
}
.influencer_profile .influ_prfl_box2 .business_account .comn_profl_lyout h1 span {
    font-size: 44px;
    color: #277ae9;
}
.influencer_profile .influ_prfl_box2 .business_account .comn_profl_lyout p {
    font-size: 14px;
}
.influencer_profile .influ_prfl_box3 .comn_profl_lyout {
    padding-bottom: 0;
}
.influencer_profile .btn_layout {
    margin: 0 10px;
}

.dwnload_exl,
.dwnload_exl:hover {
    background-color: #ffffff;
    border: solid 1px #6599ff;
    color: #6599ff;
    padding: 15px 20px 15px 50px;
    background: url("../images/download_exl_icon.png") no-repeat center left 20px;
    line-height: initial;
}

.btn_layout2,
.btn_layout2:hover {
    width: 100%;
    background: transparent;
    color: #277ae9;
    border: solid 1px #277ae9;
    padding: 15px 5px;
    font-size: 16px;
    font-weight: 600;
}

.btn_layout2.bg_skyblue,
.btn_layout2.bg_skyblue:hover {
    background-color: #277ae9;
    color: #ffffff;
}

.disclosure_detls h4 {
    font-size: 26px;
    font-weight: bold;
    color: #333333;
}
.disclosure_detls p {
    font-size: 18px;
    font-weight: bold;
    color: #777777;
    margin: 0 0 40px;
}
.disclosure_detls .disclosure_detls_box {
    margin: 0 0 30px;
}
.disclosure_detls .disclosure_detls_box h6 {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
}
.disclosure_detls .disclosure_detls_box ul {
    list-style: decimal;
}
.disclosure_detls .disclosure_detls_box ul li {
    font-size: 16px;
    color: #333333;
    margin: 0 0 10px;
    line-height: 28px;
}
.disclosure_detls .disclosure_detls_box ul li ul {
    list-style: lower-roman;
    margin-top: 10px;
}
.disclosure_detls .disclosure_detls_box ul li ul ul {
    list-style: disc;
}
.disclosure_detls .disclosure_detls_box ul li ul ul a {
    color: #277ae9;
}

.notice_impo {
    color: #ff6666;
    font-size: 16px;
}

.star {
    display: inline-block !important;
    font-size: 16px !important;
}

#product_recall p {
    font-size: 18px;
}
#product_recall button {
    line-height: initial !important;
}

.recuritstatus_detail1 .recuritstatus_box {
    background-color: #fff;
    padding: 15px 10px 15px;
}
.recuritstatus_detail1 .recuritstatus_box .recuritstatus_profile_info {
    padding: 0 20px;
    text-align: left;
}
.recuritstatus_detail1 .recuritstatus_box .recuritstatus_profile_info h3 {
    margin-top: 20px !important;
}
.recuritstatus_detail1 .recuritstatus_box .recuritstatus_profile_info h3 span {
    font-size: 18px;
}
.recuritstatus_detail1 .recuritstatus_box .recuritstatus_profile_info img {
    width: 100% !important;
    height: auto !important;
}
.recuritstatus_detail1 .recuritstatus_box .btn_iconss {
    text-align: center;
    width: auto;
    margin: 0;
    position: absolute;
    top: -30px;
    right: 0;
}
.recuritstatus_detail1 .recuritstatus_box .btn_iconss > div {
    font-size: 18px;
    font-weight: normal;
    width: 58px;
    height: 58px;
    border-radius: 100%;
    margin: 0 10px 0px 0;
    position: relative;
    vertical-align: top;
    padding: 0;
}
.recuritstatus_detail1 .recuritstatus_box .btn_iconss > div span {
    color: #277ae9;
    opacity: 1;
    font-size: 14px;
}
.recuritstatus_detail1 .recuritstatus_box .btn_iconss .percent {
    padding: 17px 2px;
    background-color: #e0ecfc;
    color: #277ae9;
}
.recuritstatus_detail1 .recuritstatus_box .btn_iconss .select_confmd {
    font-size: 16px;
    padding-top: 12px;
    text-align: center;
}
.recuritstatus_detail1 .recuritstatus_box .recuritstatus_detailinfo > div.row {
    padding: 0 20px;
}
.recuritstatus_detail1 .recuritstatus_box .recuritstatus_detailinfo > div.row .btn_layout2,
.recuritstatus_detail1 .recuritstatus_box .recuritstatus_detailinfo > div.row .btn_layout2:hover {
    padding: 10px 5px;
    border-radius: 30px;
    line-height: initial;
}
.recuritstatus_detail1 .recuritstatus_box .ai_tech {
    background: #f7f7f7;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0 10px;
}
.recuritstatus_detail1 .recuritstatus_box.influe_selected {
    background-color: #f4f6f9;
}
.recuritstatus_detail1 .recuritstatus_box.influe_selected .btn_iconss .percent {
    background-color: #277ae9;
    color: #fff;
}
.recuritstatus_detail1 .recuritstatus_box.influe_selected .btn_iconss .percent span {
    color: #fff;
}
.recuritstatus_detail1 .recuritstatus_box.influe_selected .ai_tech {
    background: #e4eaf4;
}

#posting_status .btn_layout {
    margin: 0 5px;
}

#product_recall .btn_layout,
.campaign_modal .btn_layout {
    padding: 20px 60px;
    font-size: 22px;
}
#product_recall p,
.campaign_modal p {
    font-size: 22px;
}

.extra_text {
    color: #ff6666;
    font-size: 14px;
    font-weight: 700;
}

.landing_page_btn {
    display: none;
}

/*  Responsive style  */
@media screen and (max-width: 1580px) {
    #posting_status .btn_layout {
        padding: 15px 60px;
        margin: 0 5px;
    }
    .main_header {
        z-index: 999;
        position: relative;
    }
    .hm_banner .floding_effect {
        width: 9%;
        z-index: 99;
    }
    .header_logo {
        width: 15% !important;
    }
    .header_logo img {
        width: 100%;
        height: auto;
    }
    .menu_bar ul.navbar-nav {
        z-index: 9999;
        position: relative;
    }
    .menu_bar ul .after_login {
        width: 50%;
    }
    .tab_panel > .container,
    .register_form > .container {
        width: 55%;
    }
    .mycampaign .completed_campaign table td:nth-child(3) {
        width: 210px;
    }
    .mycampaign .completed_campaign table td:nth-child(4) {
        width: 255px;
    }
    .register_campaign_tab .tab-content .common_review .container,
    .edit_reeview .container {
        width: 100%;
    }
    .check_campaign_request .campaign_request_detail .container {
        width: 100%;
    }
    .findresults {
        padding: 40px 20px;
    }
    .edit_reeview .clander_input input {
        width: 174px;
        margin: 0 0 0 10px;
    }
    .form-check-label {
        font-size: 14px;
    }
    .edit_reeview .container .form-label {
        font-size: 14px;
    }
    .landing_page .hm_banner .banner_body .banner_text .container {
        width: 70%;
    }
    .landing_page .hm_banner .banner_body .banner_text h1 {
        font-size: 56px;
    }
}
@media screen and (max-width: 1024px) {
    .hm_banner .floding_effect {
        width: 8%;
        z-index: 9;
    }
    .header_logo {
        width: 30% !important;
    }
    .header_logo img {
        width: 100%;
        height: auto;
    }
    .menu_bar ul .after_login {
        width: 50%;
    }
    .landing_page .hm_banner .banner_body .banner_text .container {
        width: 70%;
    }
    .landing_page .hm_banner .banner_body .banner_text h1 {
        font-size: 36px;
    }
}
@media screen and (max-width: 991px) {
    .landing_page_btn {
        display: inline-block;
    }
    .hm_banner .floding_effect {
        display: none;
    }
    .hm_banner .banner_body .banner_text {
        margin-top: 100px;
    }
    .hm_banner .banner_body .banner_text h1 {
        margin-bottom: 30px;
        font-size: 30px;
    }
    .login_modal .modal-body {
        padding: 50px 30px;
    }
    .tab_panel > .container {
        width: 70% !important;
    }
    .register_form > .container,
    .tab_panel > .container,
    .common_layout .container {
        width: 95%;
    }
    .inner_heading {
        font-size: 38px;
        margin: 0 0 50px;
    }
    .mtb190 {
        margin-top: 50px;
        margin-bottom: 80px;
    }
    .footer {
        padding: 40px 0;
    }
    .footer .box_left {
        order: 3;
    }
    .benefit_pts .benefit_pts_dtls {
        margin-top: 50px;
    }
    .benefit_pts .benefit_pts_dtls .text_info {
        margin-top: 0;
    }
    .benefit_pts .benefit_pts_dtls .text_info h1 {
        font-size: 26px;
        line-height: 40px;
    }
    .benefit_pts .benefit_pts_dtls .text_info h1 img.icon_img {
        width: 70px;
    }
    .benefit_pts .benefit_pts_dtls .text_info h1 img.icon_img2 {
        left: 100px;
        bottom: 0;
    }
    .benefit_pts .benefit_pts_dtls .text_info h1 img.icon_img3 {
        left: 130px;
    }
    .benefit_pts .benefit_pts_dtls .text_info h1 img.icon_img5 {
        top: 0;
    }
    .benefit_pts .benefit_pts_dtls .text_info p {
        font-size: 20px;
        margin: 0 0 20px;
    }
    .user_modal .modal-content {
        padding: 30px 20px;
    }
    .user_modal .modal-content .modal-header h5 {
        font-size: 22px;
        margin: 0px 0 10px;
    }
    .user_modal .modal-content .modal-body {
        padding: 0;
    }
    .user_modal .modal-content .modal-body .notice {
        padding: 15px;
    }
    .user_modal .modal-content .modal-body .row > div:nth-child(2) {
        margin: 0 0 10px;
    }
    .user_modal .modal-content .modal-body .mb-5 {
        margin-bottom: 1.5rem !important;
    }
    .user_modal .modal-content .modal-body .form-check-inline {
        margin-right: 5px;
    }
    .landing_page .hm_banner .banner_body .banner_text .row {
        width: 46%;
    }
    .landing_page .hm_banner .banner_body .banner_text h1 {
        font-size: 14px !important;
    }
    .landing_page .hm_banner .banner_body .banner_text .row .app_btns {
        width: 50%;
        padding: 0 5px;
    }
    .landing_page .hm_banner .banner_body .banner_text .row .app_btns a {
        margin: 0;
    }
}
@media screen and (max-width: 767px) {
    .main_header {
        padding: 10px 0;
        display: block;
    }
    .main_header .header_logo {
        width: 30% !important;
        float: left;
    }
    .main_header .navbar-toggler {
        float: right;
    }
    .main_header .navbar-collapse.show {
        background: #fff;
        padding: 20px;
        float: left;
        border-radius: 8px;
    }
    .main_header .navbar-collapse.show .nav-item {
        border-bottom: solid 1px #bebebe;
        padding: 5px 14px;
    }
    .main_header .menu_bar ul li:first-child a::after {
        display: none;
    }
    .main_header .menu_bar ul .after_login {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        margin-top: 0px;
    }
    .header {
        padding: 5px 0px 15px;
    }
    .header .navbar {
        justify-content: space-between !important;
    }
    .hm_banner .banner_body .banner_text {
        margin-bottom: 0px;
        margin-top: auto;
    }
    .hm_banner .banner_body .banner_text h1 {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .hm_banner .banner_body .banner_text .btn_layout {
        padding: 8px 30px;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .benefit_pts .benefit_pts_dtls:last-child,
    .benefit_pts .benefit_pts_dtls:nth-child(4),
    .benefit_pts .benefit_pts_dtls:first-child {
        margin-top: 10px;
    }
    .benefit_pts .benefit_pts_dtls:nth-child(3) {
        margin-top: 0;
    }
    .benefit_pts .benefit_pts_dtls:nth-child(4) .text_info,
    .benefit_pts .benefit_pts_dtls:nth-child(5) .text_info {
        margin-top: 10px;
    }
    .benefit_pts {
        padding: 20px 0;
    }
    .benefit_pts .benefit_pts_dtls {
        margin-top: 20px;
        flex-direction: column;
    }
    .benefit_pts .benefit_pts_dtls img {
        width: 100%;
    }
    .benefit_pts .benefit_pts_dtls .text_info {
        order: -1;
    }
    .benefit_pts .benefit_pts_dtls .text_info p {
        font-size: 14px;
        margin: 0 0 10px;
    }
    .benefit_pts .benefit_pts_dtls .text_info h1 {
        font-size: 18px;
        line-height: 24px;
    }
    .benefit_pts .benefit_pts_dtls .text_info h1 img.icon_img {
        display: none;
    }
    .campaign_process {
        padding: 30px 0 20px;
    }
    .campaign_process .heading {
        font-size: 18px;
        margin: 0 0 10px;
        line-height: 24px;
    }
    .campaign_process .campaign_process_pts .row {
        flex-direction: column;
    }
    .campaign_process .campaign_process_pts .number {
        margin: 0 0 15px;
    }
    .campaign_process .campaign_process_pts img {
        width: 20%;
        float: left;
    }
    .campaign_process .campaign_process_pts h4 {
        margin: 0 0 5px;
        width: 70%;
        float: right;
        font-size: 16px;
    }
    .campaign_process .campaign_process_pts p {
        width: 70%;
        float: right;
        font-size: 14px;
        line-height: 18px;
    }
    footer {
        padding: 20px 0;
    }
    footer .footer_logo img {
        width: 40%;
    }
    footer h5 {
        font-size: 16px;
    }
    footer ul.term_policy {
        margin-top: 10px;
    }
    footer ul.social_links {
        text-align: left;
        margin: 10px 0;
    }
    footer ul.social_links li a {
        padding: 0 15px 0 0;
        font-size: 12px;
    }
    footer ul.social_links li img {
        width: 25px;
    }
    footer .box_right {
        margin: 10px 0;
    }
    footer p,
    footer a,
    footer .mail_btn,
    footer .mail_btn:hover,
    footer .mail_btn:active {
        line-height: 22px;
        font-size: 12px;
    }
    .scroll__container {
        display: none;
        right: 16px;
        bottom: 85px;
    }
    .scroll__container img {
        width: 40px;
    }
    .hr_line {
        height: 65px;
    }
    .inner_heading {
        font-size: 24px;
        margin: 0 0 20px;
    }
    .mycampaign_detail p.simple_text {
        margin: 0 0 20px !important;
    }
    .mb-5 {
        margin-bottom: 20px !important;
    }
    .review_small_width .container {
        width: 100%;
    }
    .review_small_width .review_category {
        margin-bottom: 20px;
    }
    .review_small_width .review_category .form-check-inline {
        margin-bottom: 0px !important;
    }
    .form-check-inline {
        margin-right: 10px;
    }
    .btn_layout {
        padding: 12px 30px;
    }
    .mycampaign .btn_layout {
        font-size: 18px;
    }
    .findresults {
        padding: 20px;
        margin: 0 0 10px;
    }
    .campaign_cost .container,
    .register_campaign_tab.tab_panel .container {
        width: 100% !important;
    }
    .campaign_cost .row {
        padding: 15px 10px 10px;
    }
    .campaign_cost .row .text-end {
        text-align: left !important;
    }
    .register_campaign_tab.tab_panel .nav-fill .nav-item .nav-link {
        font-size: 14px;
    }
    .register_campaign_tab.tab_panel .tab-content .common_review {
        margin-top: 0;
    }
    .register_campaign_tab.tab_panel .tab-content .common_review .container .mb-7 {
        margin-bottom: 20px;
    }
    .register_campaign_tab.tab_panel .tab-content .common_review .container .gender_age > .row .width_10 {
        width: 100%;
        text-align: left !important;
    }
    .register_campaign_tab.tab_panel .tab-content .common_review .form-label {
        margin-bottom: 10px;
        font-size: 14px;
    }
    .register_campaign_tab.tab_panel .tab-content .common_review .form-label span.numbers {
        font-size: 16px;
    }
    .mtb190 {
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .mt-5 {
        margin-top: 20px !important;
    }
    .clander_input input {
        margin-bottom: 5px;
    }
    .campaign_request_detail .container .row > div {
        text-align: left !important;
    }
    .login_modal .btn_layout {
        padding: 16px;
    }
    .tab_panel > .container {
        width: 90% !important;
    }
    .tab_panel .nav-fill .nav-item .nav-link {
        font-size: 16px;
    }
    .col-form-label,
    .form-label {
        padding-left: 15px;
    }
    .register_form .term_condition {
        padding: 10px 10px 0;
    }
    .term_condition .form-check-label {
        display: inline;
    }
    .faq_panel .faq_details .accordion-item {
        padding: 0 30px;
    }
    .faq_panel .accordion button {
        font-size: 16px;
    }
    .faq_panel .faq_details h3 {
        font-size: 20px;
    }
    .faq_panel .faq_details {
        margin: 0 0 30px;
    }
    .mycampaign .nav-tabs .nav-item .nav-link {
        font-size: 14px;
        padding: 15px 40px;
        margin-top: 5px;
    }
    .mycampaign .ongoing_campaign table {
        width: 600px;
    }
    .mycampaign .ongoing_campaign table th {
        width: 100px !important;
        padding: 10px;
    }
    .mycampaign .ongoing_campaign table td {
        width: 100px !important;
        padding: 10px;
    }
    .mycampaign .ongoing_campaign .countdown_box > div {
        width: 33% !important;
        padding: 5px;
    }
    .mycampaign .ongoing_campaign .countdown_box > div:last-child {
        width: 100% !important;
    }
    .mycampaign .countdown .countdown_info p span.date_time {
        position: relative;
        top: -20px;
        font-size: 18px;
    }
    .mycampaign .completed_campaign .countdown .width_50 {
        width: 50% !important;
        padding: 5px;
    }
    .mycampaign .completed_campaign .graph .graph_details .average_data {
        margin: 0 0 10px;
    }
    .mycampaign .form-select {
        font-size: 16px;
        padding: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
    .mycampaign.faq_panel.mtb190 .col-sm-2.float-end {
        width: 100%;
    }
    .campaign_pymt_done .container {
        width: 90%;
    }
} /*# sourceMappingURL=style.css.map */
