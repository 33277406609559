/* #drop-zone .dz-message {
    display: none;
} */
.imageFiles {
    display: flex;
    list-style-type: none;
    height: 150px;
    width: 100%;
    /* border: 1px solid red; */
}
.image-box {
    width: 25%;
    /* border: 1px solid orange; */
}
.input-image {
    height: 100px;
    width: 100%;
    padding: 0 !important;
}
.input-image p {
    padding: 15px;
}
.image-li {
    height: 90%;
    width: 95%;
    /* margin-left: 15px; */
}
.image-span {
    text-align: center;
    width: 30px;
    height: 30px;
    background-color: white;
    border: 1px solid lightgrey;
    margin-left: 25px;
}
.list-image {
    width: 80%;
    height: 100%;
    object-fit: cover;
}
