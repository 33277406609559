/* Tooltip 컨테이너 스타일 */
.tooltip-container {
    background-color: white;
    color: black;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23);
}

.title-text {
    font-weight: bold !important;
    color: black !important;
}

/* 툴팁 화살표 스타일 */
/* .tooltip-container::before {
    content: "";
    position: absolute;
    bottom: 100%; 
    left: 50%;
    margin-left: -5px; 
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(255, 255, 255, 0.8) transparent;
} */
