@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Black-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Black-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Black-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Black-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Black-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Black-Alphabetic.svg#NotoSansKR-Black-Alphabetic") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Thin-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Thin-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Thin-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Thin-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Thin-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Thin-Alphabetic.svg#NotoSansKR-Thin-Alphabetic") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Regular-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Regular-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Regular-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Regular-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Regular-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Regular-Alphabetic.svg#NotoSansKR-Regular-Alphabetic") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Bold-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Bold-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Bold-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Bold-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Bold-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Bold-Alphabetic.svg#NotoSansKR-Bold-Alphabetic") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Medium-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Medium-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Medium-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Medium-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Medium-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Medium-Alphabetic.svg#NotoSansKR-Medium-Alphabetic") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NotoSansKR";
    src: url("../fonts/NotoSansKR-Light-Alphabetic.eot");
    src: url("../fonts/NotoSansKR-Light-Alphabetic.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansKR-Light-Alphabetic.woff2") format("woff2"),
        url("../fonts/NotoSansKR-Light-Alphabetic.woff") format("woff"), url("../fonts/NotoSansKR-Light-Alphabetic.ttf") format("truetype"),
        url("../fonts/NotoSansKR-Light-Alphabetic.svg#NotoSansKR-Light-Alphabetic") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PlusJakartaSans";
    src: url("../fonts/PlusJakartaSans-Regular.eot");
    src: url("../fonts/PlusJakartaSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PlusJakartaSans-Regular.woff2") format("woff2"),
        url("../fonts/PlusJakartaSans-Regular.woff") format("woff"), url("../fonts/PlusJakartaSans-Regular.ttf") format("truetype"),
        url("../fonts/PlusJakartaSans-Regular.svg#PlusJakartaSans-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Bold.eot");
    src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff2") format("woff2"),
        url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.ttf") format("truetype"),
        url("../fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-bRg.eot");
    src: url("../fonts/NanumSquareNeoTTF-bRg.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-bRg.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-bRg.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-bRg.ttf") format("truetype"),
        url("../fonts/NanumSquareNeoTTF-bRg.svg#NanumSquareNeoTTF-bRg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-aLt.eot");
    src: url("../fonts/NanumSquareNeoTTF-aLt.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-aLt.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-aLt.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-aLt.ttf") format("truetype"),
        url("../fonts/NanumSquareNeoTTF-aLt.svg#NanumSquareNeoTTF-aLt") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-cBd.eot");
    src: url("../fonts/NanumSquareNeoTTF-cBd.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-cBd.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-cBd.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-cBd.ttf") format("truetype"),
        url("../fonts/NanumSquareNeoTTF-cBd.svg#NanumSquareNeoTTF-cBd") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-dEb.eot");
    src: url("../fonts/NanumSquareNeoTTF-dEb.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-dEb.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-dEb.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-dEb.ttf") format("truetype"),
        url("../fonts/NanumSquareNeoTTF-dEb.svg#NanumSquareNeoTTF-dEb") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NanumSquare Neo";
    src: url("../fonts/NanumSquareNeoTTF-eHv.eot");
    src: url("../fonts/NanumSquareNeoTTF-eHv.eot?#iefix") format("embedded-opentype"), url("../fonts/NanumSquareNeoTTF-eHv.woff2") format("woff2"),
        url("../fonts/NanumSquareNeoTTF-eHv.woff") format("woff"), url("../fonts/NanumSquareNeoTTF-eHv.svg#NanumSquareNeoTTF-eHv") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

$NanumSquare: "NanumSquare Neo";
$PlusJakartaSans: "PlusJakartaSans";
$NotoSansKR: "NotoSansKR";
$Montserrat: "Montserrat";

$fontWeightBlack: 900;
$fontWeightbold: bold;
$fontWeightRegular: normal;
$fontWeightMedium: 700;
$fontWeightLight: 300;
$fontWeightThin: 100;

$mt190: 190px;
$mt100: 50px;
$mb100: 100px;
$padding50: 50px 0;
$width_100: 100%;
$padding_0: 0;

$white: #ffffff;
$blue: #277ae9;
$black: #000000;
$grey: #777777;
$red: #ff6666;
$drakgray: #333333;
$lightgray: #f7f7f7;
$miduumgrey: #ebebeb;

body,
html,
p,
a,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
input,
textarea,
select,
option,
td,
th,
tr,
button,
ul,
li {
    font-family: $NanumSquare !important;
    color: $black;
    text-decoration: none;
}

img {
    max-width: 100%;
}

textarea {
    resize: none;
    font-family: $NanumSquare;
}

.color_blue {
    color: $blue;
}

.msg_time .time {
    position: absolute;
    right: 0;
    bottom: -40px;
    color: $red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox 
input[type=number] { 
  -moz-appearance: textfield;
}*/
.text-16 {
    font-size: 16px !important;
    font-weight: $fontWeightRegular !important;
}

.hr_line {
    width: $width_100;
    height: 95px;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn, rgba(243, 243, 243, 0.5), rgba(243, 243, 243), rgba(243, 243, 243, 0.4));
    border-image-slice: 1;
}

.form-control:focus {
    border-color: $miduumgrey !important;
    box-shadow: none;
}

.padding_0 {
    padding: 0;
}

.mtb190 {
    margin-top: $mt100;
    margin-bottom: $mt190;
}

.col-form-label,
.form-label {
    font-size: 16px;
    padding: 15px 0 5px;
    font-weight: $fontWeightMedium;
    color: $drakgray;
    line-height: 26px;
    margin: 0;
}

.form-check-input[type="radio"] {
    height: 28px;
    width: 28px;
    border: 8px solid #efefef;
}

.form-check-input[type="radio"]:checked {
    border: 2px solid #277ae9;
}

.form-control,
.form-select,
.input-group-text,
.edit_save_btn > button,
.user_modal button {
    font-size: 16px;
    padding: 15px;
    font-weight: $fontWeightRegular;
    line-height: 26px;
    color: #000;
    border-color: $miduumgrey;
}

.required_field,
.text-danger {
    color: $red !important;
}

.form-check-input[type="checkbox"] {
    border-radius: 100%;
    padding: 0;
    height: 28px;
    width: 28px;
    background: url("../images/check_box_icon.png") no-repeat center left;
    border: none;
}

.form-check-input[type="checkbox"]:checked {
    background: url("../images/check_box_icon_chk.png") no-repeat center left;
}

.form-check-label {
    line-height: initial;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: normal;
    color: $grey;
}

.input-group-text,
.input-group-text:hover,
.btn_layout.un_active,
.btn_layout.un_active:hover,
.edit_save_btn > button,
.edit_save_btn > button:hover,
.user_modal button,
.user_modal button:hover,
.btn-primary.un_active {
    color: $white;
    background: #cbcbcb;
    border-color: #cbcbcb;
}

.input-group-text.active,
.input-group-text.active:hover,
.btn_layout.active,
.edit_save_btn > button.save_btn1,
.btn-primary.active {
    background-color: $blue;
    border-color: $blue;
}

.multi_check_box {
    .form-check-input {
        display: contents;
    }

    .form-check-label {
        margin-left: -25px;
        border: solid 1px $miduumgrey;
        border-radius: 30px;
        padding: 10px 22px;
        font-size: 16px;
        font-weight: $fontWeightMedium;
        margin-bottom: 10px;
        color: $drakgray;
    }

    .form-check-input:checked + .form-check-label {
        background-color: $blue;
        border-color: $blue;
        color: $white;
    }
}

.btn_layout {
    background-color: $blue;
    border-radius: 100px;
    color: $white;
    padding: 20px 120px;
    line-height: initial;
    display: inline-block;
    font-weight: $fontWeightMedium;
    text-decoration: none;
    border: solid 1px $blue;
    font-size: 18px;
    width: auto;
    margin: 0 auto;
}

.btn_layout:hover {
    background-color: $blue;
    border: solid 1px $blue;
    color: $white;
}

.width_100 {
    width: $width_100;
}

.heading {
    font-size: 44px;
    color: $black;
    margin: 0 0 100px;
    font-weight: $fontWeightMedium;
    line-height: 70px;
}

.inner_heading {
    font-size: 48px;
    color: #000;
    font-weight: $fontWeightMedium;
    margin: 0 0 20px;
    text-align: center;
}

.mleft10 {
    margin-left: 10px;
}

.nav-link:hover {
    color: $blue;
}

.nav-link.custom_btn:focus,
.nav-link.custom_btn:hover {
    color: $white;
}

.mt100 {
    margin-top: $mt100;
}

.mb100 {
    margin-bottom: $mb100;
}

.notice {
    border: solid 1px #ccc;
    padding: 30px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: $fontWeightRegular;
}

.not_fixed {
    background: transparent;
    position: absolute;
    width: 100%;
    //  z-index: 99;
    box-shadow: none;
    padding: 0px 0;
    top: 0;
}

.main_header {
    padding: 20px 0;
    z-index: 999;
    position: relative;

    .navbar-collapse {
        .navbar-nav {
            margin-top: 5px;
        }
    }
}

.header {
    background-color: $white;
    box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.3);
    -webkit-animation: slideDown 0.35s ease-out;
    -ms-animation: slideDown 0.35s ease-out;
    animation: slideDown 0.35s ease-out;
    -webkit-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 0px 0;
}

.header_logo {
    width: 20%;
    img {
        width: 100%;
        height: auto;
    }
}

.menu_bar ul {
    li {
        a {
            font-size: 18px;
            font-weight: $fontWeightbold;
            color: $drakgray;
            position: relative;
            padding-bottom: 0;
        }

        button.btn_layout {
            font-size: 14px;
            font-weight: $fontWeightbold;
            padding: 10px 25px;
        }
    }

    .after_login {
        width: 30%;
        text-align: center;
        margin-top: -10px;

        a {
            color: #000;
            font-weight: $fontWeightRegular;
            font-size: 16px;

            span.user_name {
                font-weight: $fontWeightbold;
                font-size: 20px;
            }

            span.bran_name {
                color: $blue;
                font-weight: $fontWeightMedium;
            }
        }

        .dropdown-toggle::after {
            display: none;
        }

        .dropdown-menu {
            box-shadow: 0 0px 8px 0px rgba(0, 0, 0, 0.2);
            border: none;
            padding: 20px 0;
            margin: 15px 0 0;
            border-radius: 10px;

            li {
                padding: 0;

                a {
                    line-height: initial;
                    padding: 10px 5px;
                    font-size: 14px;
                    color: $grey;
                    font-weight: $fontWeightbold;
                }

                a:hover {
                    background-color: $blue;
                    color: $white;
                }
            }

            li:nth-last-child(2) {
                margin-bottom: 15px;
            }

            li.nav-item a {
                width: auto;
                border-top: solid 1px #dfe1e8;
                background: $white;
                color: #999999;
                padding: 25px 20px 15px;
            }

            li.nav-item a:hover {
                color: $blue;
            }
        }
    }
}

.menu_bar ul li:first-child a::after {
    content: "|";
    padding-left: 15px;
}

.menu_bar ul .after_login .dropdown-menu a::after {
    display: none;
}

.landing_page {
    .hm_banner {
        .banner_body {
            align-items: center;

            .banner_text {
                text-align: left;
                margin-bottom: 0px;
                h1 {
                    font-size: 70px;
                }

                .row {
                    width: 36%;
                    margin-top: -100px;
                    margin-left: 3%;

                    .bar_code {
                        margin-bottom: 40px;
                        text-align: right;
                        img {
                            width: 80%;
                        }
                    }

                    .app_btns {
                        a {
                            margin: 0 0 30px;
                            float: left;
                        }
                    }
                }
            }
        }
    }
}

.hm_banner {
    width: 100%;
    position: relative;

    img {
        width: 100%;
    }

    .floding_effect {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
    }

    .banner_body {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: end;

        .banner_text {
            width: 100%;
            text-align: center;
            margin-bottom: 80px;

            h1 {
                margin-bottom: 40px;
                font-size: 60px;
                font-weight: $fontWeightMedium;
                color: $black;
            }

            a.btn_layout {
                font-size: 20px;
            }
        }
    }
}

.benefit_pts {
    width: 100%;
    padding: 30px 0 200px;

    .benefit_pts_dtls:last-child,
    .benefit_pts_dtls:nth-child(4),
    .benefit_pts_dtls:first-child {
        margin-top: 100px;
    }

    .benefit_pts_dtls:nth-child(3) {
        margin-top: 30px;
    }
    .benefit_pts_dtls:nth-child(4) .text_info,
    .benefit_pts_dtls:nth-child(5) .text_info {
        margin-top: 60px;
    }

    .benefit_pts_dtls {
        margin-top: 0px;

        .text_info {
            margin-top: 140px;
        }

        p {
            font-size: 24px;
            color: $blue;
            margin: 0 0 30px;
            font-weight: 600;
        }
        h1.fontsize_40 {
            font-size: 40px;
        }

        h1 {
            font-size: 50px;
            font-weight: $fontWeightMedium;
            color: $black;
            position: relative;
            line-height: 70px;

            img.icon_img {
                padding: 0;
                width: 130px;
                position: absolute;
                bottom: -40px;
                left: 31%;
                z-index: -1;
            }

            img.icon_img2 {
                left: 145px;
                bottom: -90px;
            }

            img.icon_img3 {
                left: 245px;
                top: 0;
            }

            img.icon_img4 {
                left: 111px;
                bottom: -35px;
            }

            img.icon_img5 {
                left: 146px;
                top: -100px;
            }
        }

        div {
            padding: 0 20px 20px;
        }

        img {
            width: 90%;
            float: left;
        }

        .float_right {
            float: right;
        }
    }
}

.campaign_process {
    background-color: #f7f8fa;
    padding: 150px 0;

    .campaign_process_pts {
        .number {
            font-size: 18px;
            font-weight: $fontWeightMedium;
            margin: 0 0 65px;

            span {
                border-top: solid 2px $black;
                padding-top: 12px;
            }
        }

        img {
            max-width: 100%;
            width: auto;
        }

        h4 {
            font-size: 22px;
            font-weight: $fontWeightMedium;
            margin: 55px 0 30px;
        }

        p {
            font-size: 16px;
            color: $grey;
            font-weight: $fontWeightRegular;
            line-height: 24px;
        }

        .card {
            border: none;
            background: transparent;
        }
    }
}

footer {
    background: #303041;
    width: 100%;
    float: left;
    padding: 80px 0;

    .footer_logo {
        margin: 0 0 30px;

        img {
            max-width: 100%;
            width: 15%;
        }
    }

    p,
    a {
        font-size: 15px;
        color: #acacb6;
        line-height: 24px;
        margin: 0;
    }

    p {
        span {
            color: #acacb6;
        }
    }

    h5 {
        font-size: 18px;
        font-weight: $fontWeightbold;
        color: #acacb6;
    }

    ul.term_policy {
        margin-top: 30px;
        text-align: left;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: right;

        li {
            display: inline-block;

            a {
                color: #acacb6;
                padding: 0 13px;
                text-decoration: none;
                position: relative;
                font-size: 15px;
            }

            a:hover {
                color: #acacb6;
            }
        }
    }

    .mail_btn,
    .mail_btn:hover,
    .mail_btn:active {
        background: transparent;
        border: none;
        padding: 0;
        color: #acacb6;
        font-size: 16px;
        line-height: 30px;
    }

    .position_relative {
        position: relative;

        .success-msg {
            position: absolute;
            top: 30px;
            background: $white;
            color: #000;
            padding: 6px 10px;
            z-index: 9;
            border-radius: 10px;
            font-size: 14px;
            font-weight: $fontWeightbold;
        }
    }
}

footer ul.term_policy li a:after {
    position: absolute;
    content: "|";
    padding-left: 13px;
}

footer ul.term_policy li:last-child a:after {
    display: none;
}

footer ul li:first-child a {
    padding-left: 0;
}

footer ul li:last-child a {
    padding-right: 0;
}

.login_modal {
    .modal-content {
        border-radius: 0;
    }

    .modal-body {
        padding: 80px 90px;
    }

    .popup_logo {
        width: 100%;
        margin: 0 0 50px;
        text-align: center;

        img {
            width: 50%;
        }
    }

    input {
        padding: 20px;
        line-height: initial;
        font-size: 16px;
        font-weight: $fontWeightMedium;
        border-color: $miduumgrey;
        color: #bbbbbb;
        font-weight: $fontWeightRegular;
    }

    input[type="checkbox"] {
        border-radius: 100%;
        padding: 0;
        height: 28px;
        width: 28px;
    }

    .form-check-label {
        line-height: initial;
        padding: 8px 10px;
        font-size: 16px;
        font-weight: $fontWeightRegular;
        color: $grey;
    }

    .btn_layout {
        padding: 22px;
        font-size: 18px;
        font-weight: $fontWeightMedium;
    }

    .find_pass_id {
        font-size: 14px;
        color: $grey;
        padding-bottom: 20px;
        border-bottom: solid 1px $miduumgrey;

        .text-end {
            border-right: solid 1px $miduumgrey;
        }
    }

    .register_btn {
        .btn_layout {
            background-color: $white;
            color: $blue;
        }

        P {
            font-size: 14px;
            color: $grey;
        }

        /*.btn_layout:hover {
            background-color: $blue;
            color: $white;
        }
        */
    }
}

.register_form > .container,
.tab_panel > .container,
.common_layout .container {
    width: 45%;
}

.tab_panel > .container {
    width: 33%;
}

.register_form {
    width: 100%;

    .term_condition {
        padding: 20px 20px 0;
        border: solid 1px $miduumgrey;
        border-radius: 6px;
        margin: 5px 0 20px;

        .form-check {
            div {
                margin: 0 0 15px;
            }
        }
    }
}

.findresult_panel,
.findresult_panel_ad {
    width: 100%;
    margin: 0 0 30px;
}

.findresults {
    width: 100%;
    padding: 50px 80px;
    text-align: center;
    margin: 0 0 30px;
    /*background: $lightgray;*/
    border-radius: 10px;
    border: solid 3px #edf3fc;

    h5 {
        font-size: 24px;
        color: #000;
        font-weight: $fontWeightbold;
        margin: 0 0 30px;
    }

    p {
        font-size: 18px;
        color: #000;
        margin: 0;
    }

    .btn_layout {
        margin: 50px 0 0;
    }

    .findresults_show {
        padding: 10px 0 0;
        p {
            padding: 0;
            span.left {
                margin-right: 10px;
            }
            span.right {
                margin-left: 10px;
            }
        }
    }
}

// Tabs css start

.tab_panel {
    .nav-fill {
        border-bottom: solid 1px #cbcbcb;

        .nav-item {
            .nav-link {
                width: 100%;
                border-bottom: solid 0px #cbcbcb;
                border-top: none;
                border-left: none;
                border-right: none;
                color: $grey;
                font-weight: $fontWeightRegular;
                font-size: 18px;
                color: $grey;
            }

            .nav-link.active {
                border-bottom: solid 4px $blue;
                color: $blue;
                font-weight: $fontWeightbold;
            }
        }
    }

    .tab-content {
        padding: 20px 0;

        .input-group-text {
            cursor: pointer;
            margin-left: 10px !important;
            border-radius: 4px !important;
            width: 150px;
        }

        .input-group input.form-control {
            border-radius: 8px !important;
        }

        .register_form > .container {
            width: 100%;
        }
    }
}

.userinfo_data .input-group .edit_save_btn {
    margin-left: 10px !important;
    border-radius: 4px !important;
}

.userinfo_data .input-group .edit_save_btn button {
    position: unset;
    width: 100%;
}

.delete_account_btn button,
.delete_account_btn button:hover {
    margin: 50px 0;
    background: transparent;
    border: none;
    border-bottom: solid 1px $grey;
    border-radius: 0;
    padding: 0;
    color: $grey;
    font-size: 16px;
    font-weight: $fontWeightMedium;
}

.user_modal {
    .modal-content {
        padding: 70px;
    }

    .modal-header {
        border: none;
        padding: 0;

        h5 {
            width: 100%;
            margin: 0px 0 30px;
            font-weight: $fontWeightMedium;
            font-size: 36px;
            color: #000;
        }
    }

    .modal-body {
        .notice {
            border: solid 1px #ccc;
            padding: 30px;
            border-radius: 10px;
        }
    }

    .save_btn1 {
        margin: 20px 0;
    }
}

.faq_panel {
    .nav-tabs {
        width: auto;
        display: inline-block;
        border: none;

        .nav-item {
            text-align: center;
            float: left;
            margin-right: 10px;

            .nav-link {
                padding: 20px 80px;
                border: solid 1px $blue;
                border-radius: 6px;
                font-size: 16px;
                font-weight: $fontWeightMedium;
                color: $blue;
            }

            .nav-link.active {
                background: $blue;
                color: $white;
                border: solid 1px $blue;
            }
        }
    }

    .accordion {
        .accordion-header {
            padding: 20px 0;
        }

        .accordion-item:last-child .accordion-header {
            border: none;
        }

        button {
            left: 0;
            top: 0;
            border: none;
            background-color: transparent;
            justify-content: left;
            padding: 0 0px;
            font-size: 18px;
            font-weight: $fontWeightMedium;
            color: $drakgray;
        }

        .accordion-button::after {
            display: none;
        }

        .accordion-button:not(.collapsed) {
            box-shadow: none;
            background-color: $white;
        }

        .accordion-collapse.collapse.show {
            margin: 0 0 0px;
        }

        .accordion-button:focus {
            border: none;
            box-shadow: none;
        }

        .accordion-body {
            font-size: 16px;
            font-weight: $fontWeightRegular;
            color: #333;
            padding: 0 0 20px;
        }

        .accordion-item {
            border: none;
            background: transparent;
            border-bottom: solid 1px $lightgray;
        }
    }

    .faq_details {
        width: 100%;
        margin: 0 0 70px;

        h3 {
            font-weight: $fontWeightbold;
            font-size: 24px;
            color: #000;
            margin: 0 0 10px;
            border-bottom: solid 1px $lightgray;
            background: #edf3fc;
            padding: 10px 20px;
            line-height: initial;
        }

        .accordion-item {
            padding-left: 40px;
        }
    }
}

.contactus_data {
    .col-sm-2.form-label,
    .col-sm-1.form-label {
        padding-left: 20px;
    }
}

#contactusModal .modal-body p {
    font-size: 18px;
}

#contactusModal .modal-body button {
    width: auto;
    padding: 20px 150px;
    font-size: 18px;
    font-weight: $fontWeightbold;
    line-height: initial;
}

.settlement_dtls {
    table {
        border-spacing: 10px;
        border-collapse: separate;
        border-radius: 5px;

        th,
        td {
            border-radius: 5px;
            padding: 20px 10px;
            font-size: 16px;
        }

        th {
            border: solid 1px $blue;
            color: $blue;
            font-weight: $fontWeightMedium;
        }

        td {
            border: solid 1px $miduumgrey;
            color: #333;
        }
        td:nth-child(5) {
            width: 300px;
        }
    }

    .table-striped > tbody > tr:nth-of-type(even) > * {
        background-color: $lightgray;
    }

    .table-striped > tbody > tr:nth-of-type(odd) > * {
        background-color: $white;
        --bs-table-accent-bg: $white;
    }

    .page-link {
        border: none;
        border: none;
        font-size: 16px;
        padding: 8px 14px;
        line-height: initial;
        color: #666666;
        border-radius: 100% !important;
        margin: 0 10px;
    }

    .active > .page-link,
    .page-link.active {
        border-radius: 100%;
        color: $white;
    }
}

.mycampaign_detail {
    p.simple_text {
        font-size: 18px;
        font-weight: $fontWeightMedium;
        color: #000;
    }

    .mycampaign_detailinner {
        background-color: $lightgray;
        width: 100%;
        text-align: center;
        border: none;
        border-radius: 20px;
        height: 300px;

        .custom_radio {
            height: 28px;
            width: 28px;
            border: solid 1px $blue;
            display: inline-block;
            margin: 50px auto 20px;
        }

        .custom_radio:checked {
            background-image: url("../images/radio_icon.png");
            background-size: 14px auto;
            background-color: $lightgray;
        }

        .custom_label {
            padding: 0 50px;
            cursor: pointer;
            position: absolute;
            left: 0;
            right: 0;

            > div {
                padding-top: 100px;
            }
        }

        h5 {
            margin-bottom: 30px;
            font-size: 24px;
            font-weight: $fontWeightbold;
        }

        p {
            font-size: 18px;
            margin: 0;
            padding: 0 0 70px;
            line-height: 24px;
            color: #333;
            line-height: 26px;
        }

        .custom_radio:checked + .custom_label > div p,
        .custom_radio:checked + .custom_label h5 {
            color: $white;
        }
    }
}

.review_small_width {
    .container {
        width: 65%;
    }

    .review_category {
        margin-bottom: 40px;

        p {
            font-size: 18px;
            color: #000;
            font-weight: $fontWeightMedium;
            margin-bottom: 30px;

            span {
                color: $blue;
                font-size: 22px;
                border-bottom: solid 1px $blue;
                margin-right: 10px;
            }
        }

        .nopeople {
            font-weight: $fontWeightRegular;
            position: absolute;
            top: 4px;
            right: 10px;
            padding: 15px;
        }
    }

    .processRecommended1,
    .processProduct1 {
        position: relative;
    }
}

.campaign_cost {
    .container {
        width: 65%;
    }

    p {
        margin: 0;
        font-size: 18px;
        color: $drakgray;
    }

    .row {
        border-bottom: solid 1px $lightgray;
        padding: 30px;
    }

    .row.fw-bolder {
        font-weight: bold;
        background: $lightgray;
        border-radius: 10px;

        p {
            font-size: 24px;
            font-weight: $fontWeightbold;
        }
    }
}

.register_campaign_tab {
    .nav-tabs {
        .nav-link {
            border-radius: 0;
        }
    }

    .container {
        width: 100%;
    }

    .tab-content {
        .common_review {
            margin-top: 50px;

            .container {
                width: 65%;

                .mb-7 {
                    margin-bottom: 60px;
                }
            }

            .form-label {
                font-size: 18px;
                color: #000;
                font-weight: 700;
                margin-bottom: 15px;

                span.numbers {
                    color: #277ae9;
                    font-size: 22px;
                    border-bottom: solid 1px #277ae9;
                    margin-right: 10px;
                    font-weight: 700;
                }
            }

            .gender_age {
                .row {
                    border: solid 1px #ccc;
                    padding: 12px 0px;
                    border-radius: 8px;
                    margin: 0 0 10px;

                    .width_10 {
                        width: 10%;
                    }

                    .width_90 {
                        width: 90%;
                    }

                    .form-label {
                        margin: 0;
                    }
                }
            }

            .form-text {
                font-size: 16px;
                color: #b2b2b2;
                margin-top: 8px;
            }
        }
    }
}

.addFile {
    border: solid 1px $lightgray;
    margin: 0 0 5px;
    background: $blue;
    text-align: center;
    padding: 16px 50px;
    border-radius: 6px;
    font-size: 18px;
    cursor: pointer;
    color: $white;
    font-weight: $fontWeightMedium;
    line-height: initial;
}

.overlay_file1 {
    .overlay_file {
        position: absolute;
        right: 0;
        width: 77%;
        bottom: 0;
        top: 0;
    }
}

.tab-content {
    .register_posting_guide {
        label {
            padding: 8px 10px;
        }
    }
}

.basic_register_campaign {
    .addFile {
        width: auto;
        float: left;
    }

    .addFile_input {
        float: left;
        width: auto;
        margin-left: 5px;
    }

    .gender_age {
        .form-label {
            padding: 10px 0 0px;
        }
    }
}

input::file-selector-button {
    display: none;
}

.success_story {
    width: 100%;
    padding: $padding50;
    text-align: center;

    .success_story_pts {
        margin: 50px 0 0;

        img {
            border-radius: 10px;
        }
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    top: 120%;
    left: 50%;
    margin-left: -60px;
    background-color: black;
    color: $white;
    text-align: center;
    border-radius: 6px;
    padding: 10px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

//Scroll to top button
.scroll__container {
    position: fixed;
    right: 24px;
    bottom: 95px;
    z-index: 1;
    img {
        width: 56px;
    }
}

#top {
    background: transparent;
    border: none;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
}

#top:hover {
    box-shadow: 0 4px 7px 0px rgba(0, 0, 0, 0.5);
}

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
    background-color: #555;
    color: white;
    padding: 16px 10px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 28px;
    width: 60px;
    border-radius: 100%;
    height: 60px;
    text-align: center;
}

/* The popup chat - hidden by default */
.chat-popup {
    display: block;
    position: fixed;
    bottom: 90px;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
}

/* Add styles to the form container */
.form-container {
    max-width: 300px;
    padding: 10px;
    background-color: white;
}

/* Full-width textarea */
.form-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
    background-color: #ddd;
    outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
    background-color: #04aa6d;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
    background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
    opacity: 1;
}

/* The popup chat - End css */

.influencer_channel > div {
    border: none;
    padding: 0;

    div {
        background-color: #ccc;
        padding: 10px 15px;

        .form-check-input {
            margin-left: 0;
            margin-right: 10px;
        }
    }
}

.mycampaign {
    margin-bottom: 100px;
}

.h-75_custom {
    height: 200px;
}

.clander_input {
    width: 100%;
    float: left;

    input {
        width: auto;
        float: left;
        margin-right: 10px;
        padding: 20px 0px 20px 60px;
        border: solid 1px $miduumgrey;
        background: url("../images/clander_icon.png") no-repeat center left 20px;
        background-color: #efefef;
    }
}

.check_campaign_request {
    .campaign_request_detail {
        .container {
            width: 65%;

            .row {
                border-bottom: solid 1px #e6e6e6;
                padding: 20px 10px;

                h4 {
                    font-size: 18px;
                    color: #010101;
                    font-weight: $fontWeightMedium;
                    margin: 0;
                    line-height: initial;

                    span {
                        color: $blue;
                    }
                }

                p {
                    font-size: 18px;
                    color: #010101;
                    font-weight: $fontWeightRegular;
                    margin: 0;
                    line-height: initial;
                }

                .campaign_period {
                    .row:first-child {
                        padding-top: 0;
                    }

                    .row:last-child {
                        padding-bottom: 0;
                        border: none;
                    }
                }
            }
        }
    }

    .edit_campaignrq {
        margin: 50px 0 120px;
    }
}

.campaign_pymt_done {
    .container {
        width: 55%;
    }
}

.edit_reeview {
    .container {
        width: 70%;

        .form-label {
            font-size: 18px;
            color: #000;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .datepicker {
            .form-label {
                margin-bottom: 30px;
            }
        }
    }

    .clander_input input {
        width: 200px;
    }
}

.nopeopless {
    .col-sm-2 {
        position: relative;

        .nopeople {
            position: absolute;
            right: 25px;
            top: 35%;
        }
    }
}

.mrt-15 {
    top: -35px;
}

.mycampaign {
    .btn_layout {
        font-size: 24px;
    }

    .accordion {
        button.btn_layout {
            background-color: #277ae9;
            border-radius: 100px;
            color: $white;
            padding: 20px 120px;
            line-height: initial;
            display: inline-block;
            font-weight: 700;
            text-decoration: none;
            border: solid 1px #277ae9;
            font-size: 18px;
            width: auto;
            margin: 0 auto;
        }
    }

    .nav-tabs {
        .nav-item {
            .nav-link {
                font-size: 18px;
            }
        }
    }

    .form-select {
        font-size: 18px;
    }

    .findresults {
        h3 {
            font-size: 24px;
            font-weight: $fontWeightMedium;
            margin: 0 0 20px;
        }

        p {
            font-size: 16px;
            color: $drakgray;
        }
    }

    .ongoing_campaign {
        .fixed_height {
            height: 20px;
            overflow: hidden;
            line-height: 22px;
        }
        .fixed_height.recruite_btn {
            position: relative;
            top: 4px;
        }
        .recruite_btn {
            background: $blue;
            text-align: center;
            border-radius: 30px;
            height: auto;
            padding: 5px 2px;
            line-height: initial;
            color: #fff;
            a,
            button {
                color: #fff !important;
                line-height: initial;
            }
        }

        table {
            width: 100%;

            th {
                font-size: 16px;
                padding: 10px 20px;
                width: 120px;
            }
            .cursor {
                td {
                    cursor: pointer;
                }
            }

            td {
                font-size: 16px;
                font-family: $NanumSquare;
                font-weight: $fontWeightRegular;
                padding: 0 20px 10px;
                width: 150px;
            }

            td:nth-child(3) {
                width: 200px;
            }

            td:nth-child(4) {
                width: 250px;
            }
        }

        .accordion {
            a {
                color: $red;
                font-weight: $fontWeightbold;
            }

            .accordion-item {
                border-radius: 8px;
                margin-bottom: 15px;
                overflow: hidden;

                .accordion-header {
                    background-color: $lightgray;
                    padding: 0px;
                    border-bottom: solid 1px $miduumgrey;
                    border-radius: 8px;

                    .accordion-button {
                        padding: 10px;
                        border: solid 1px $blue;
                        border-bottom-color: $miduumgrey;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }

                    .accordion-button.collapsed {
                        padding: 10px;

                        border: solid 1px $miduumgrey;
                    }
                }

                .accordion-collapse {
                    padding: 30px 20px;
                }

                .accordion-collapse.collapse.show {
                    border: solid 1px $blue;
                    border-top: none;
                    margin-top: -1px;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }

            .accordion-item table thead {
                // display: none;
            }

            .accordion-item:first-child table thead {
                display: contents;
            }

            .accordion-button:not(.collapsed),
            .accordion-button.collapsed {
                background-color: $lightgray;
            }

            .accordion-button::after {
                display: block;
                background-image: url("../images/expand_icon.png");
                content: "";
                position: absolute;
                right: 2%;
                top: 35%;
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
                transform: none;
                background-size: 28px;
            }

            .accordion-button.collapsed::after {
                background-image: url("../images/collapse_icon.png");
            }
        }
    }

    .countdown {
        width: 100%;
        float: left;
        padding: 10px 20px;

        .countdown_info {
            background: #ecf3fb;
            padding: 20px 10px;
            text-align: center;
            border-radius: 8px;
            height: 90px;

            p {
                margin: 0;
                font-weight: $fontWeightRegular;
                color: $drakgray;
                font-size: 16px;

                span {
                    display: block;
                    font-weight: $fontWeightbold;
                    margin-bottom: 5px;
                }

                span.date_time_txt {
                    display: inline-block;
                    width: 35%;
                    border-right: solid 1px #bad3f5;
                    margin: 0;
                    line-height: initial;
                }

                span.date_time {
                    display: inline-block;
                    width: 65%;
                    font-size: 36px;
                    color: $blue;
                    margin: 0;
                    line-height: initial;
                }
            }
        }
    }

    .completed_campaign {
        table {
            th,
            td {
                text-align: center;
            }

            td:nth-child(1) {
                width: 200px;
            }

            td:nth-child(3) {
                width: 160px;
            }

            td:nth-child(4) {
                width: 200px;
            }
        }

        .countdown {
            padding: 10px 25px 0;

            > .row {
                border-bottom: solid 1px $miduumgrey;
                padding-bottom: 25px;
            }

            .countdown_heading {
                background-color: #93bcf4;
                padding: 35px 10px;

                p {
                    color: $white;
                }
            }
        }

        .graph {
            width: 100%;
            float: left;
            padding: 20px 30px;

            img {
                width: 100%;
            }

            .graph_details {
                padding: 70px 0;

                p {
                    font-size: 16px;
                    color: $drakgray;

                    span {
                        font-weight: $fontWeightMedium;
                    }
                }

                .average_data {
                    background: #ecf3fb;
                    text-align: center;
                    padding: 20px;
                    border-radius: 4px;

                    p {
                        font-size: 16px;
                        font-weight: $fontWeightMedium;
                        margin: 0;
                        line-height: initial;

                        span {
                            display: block;
                            font-size: 36px;
                            font-weight: $fontWeightbold;
                            margin: 5px 0 0;
                            line-height: initial;
                        }
                    }
                }
            }
        }

        .accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        border-color: $miduumgrey;
                    }
                }

                .accordion-collapse.collapse.show {
                    border-color: $miduumgrey;
                }
            }
        }

        .registered_post {
            padding: 0 30px;
            overflow: hidden;
            transition: height 0.3s ease-out;
            height: auto;

            h4 {
                font-size: 16px;
                font-weight: $fontWeightbold;
                margin: 0 0 20px;
            }

            table {
                thead {
                    background-color: $lightgray;
                    border-top: solid 1px #e6e6e6;
                }

                tr {
                    border-bottom: solid 1px #e6e6e6;
                }

                th {
                    padding: 20px;
                    font-family: $NanumSquare;
                    font-weight: $fontWeightMedium;
                }

                td {
                    width: auto;
                    padding: 15px 20px;
                    font-family: $NanumSquare;

                    p {
                        margin: 0;
                    }
                }

                td:nth-child(1),
                th:nth-child(1) {
                    width: 100px;
                }

                td:nth-child(2),
                th:nth-child(2) {
                    width: 100px;
                }

                td:nth-child(3) {
                    width: 500px;
                    vertical-align: middle;
                    text-align: left;
                }

                td:nth-child(4) {
                    width: auto;
                }
            }

            .best_view_like {
                position: relative;
                text-align: center;

                img {
                    width: 100%;
                }

                span {
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    color: $white;
                    padding: 8px 0;
                    line-height: initial;
                    text-transform: uppercase;
                    font-size: 11px;
                    font-weight: bold;
                    font-family: $NanumSquare;
                }
            }

            .best_view_like.best_view {
                span {
                    background: rgba(116, 160, 251, 0.9);
                }
            }

            .best_view_like.best_like {
                span {
                    background: rgba(241, 100, 96, 0.9);
                }
            }
        }

        .registered_post.expand_list {
            height: 200px;
            overflow: hidden;
        }
    }

    .dwnload_exl {
        background-color: $white;
        border: solid 1px #6599ff;
        color: #6599ff;
        padding: 15px 20px 15px 50px;
        background: url("../images/download_exl_icon.png") no-repeat center left 20px;
        line-height: initial;
    }
}

.dashboard {
    .image-gallery-icon {
        display: none;
    }

    .image-gallery-content {
        .image-gallery-slide {
            opacity: 0;
            transition: opacity 450ms ease-out !important;

            .image-gallery-image {
                max-height: initial;
                border-radius: 10px;
                height: 525px;
                border: solid 1px $miduumgrey;
            }
        }

        .image-gallery-slide.center {
            opacity: 1;
        }
    }

    .image-gallery-thumbnails-wrapper {
        margin: 10px 0 0;

        .image-gallery-thumbnail {
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid transparent;
            margin: 0 5px;
            width: 80px;
        }
    }

    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus,
    .image-gallery-thumbnails .image-gallery-thumbnail:hover {
        border: 1px solid $miduumgrey;
    }

    .post_recruite {
        display: block;

        button {
            background-color: $white;
            border: solid 1px $blue;
            color: $white;
            padding: 7px 30px;
            line-height: initial;
            font-size: 16px;
            border-radius: 40px;
            font-weight: $fontWeightbold;
            background-color: $blue;
        }
    }

    .post {
        button {
            background-color: $blue;
            color: $white;
        }
    }

    h3 {
        font-size: 34px;
        font-weight: $fontWeightMedium;
        margin: 0 0 20px;

        span {
            display: block;
            font-size: 16px;
            margin-bottom: 15px;
        }
    }

    p {
        font-size: 16px;
        color: #666666;
        line-height: 24px;
        font-weight: $fontWeightMedium;
        margin: 0;
    }

    .product_info {
        width: 100%;
        margin: 40px 0;
        padding: 40px 0 0;
        border-top: solid 1px $miduumgrey;

        p {
            font-size: 16px;
            margin: 0 0 20px;
            color: #666666;
            font-weight: $fontWeightRegular;

            span {
                font-weight: $fontWeightMedium;
                color: #000000;
            }
        }
    }

    .dashbrd_lwrinfo {
        width: 100%;
        margin: 80px 0 50px;

        .dashbrd_lwrinfo_detail {
            border: solid 1px $miduumgrey;
            border-radius: 8px;
            height: 100%;

            h4 {
                width: 100%;
                background-color: $lightgray;
                padding: 22px 30px;
                font-size: 18px;
                font-weight: $fontWeightMedium;
                margin: 0;
                border-bottom: solid 1px $miduumgrey;
            }

            ul {
                margin: 0;
                padding: 30px;
                padding-left: 50px;

                li {
                    list-style-type: none;
                    color: #666666;
                    font-size: 16px;
                    font-weight: $fontWeightRegular;
                    line-height: 28px;
                    word-wrap: break-word;
                }
            }
        }
    }
}

.padding_left_30 {
    padding-left: 45px;
}

.influencer {
    .nav-tabs {
        border-bottom: solid 1px #000;

        .nav-item {
            border-bottom-color: #000;

            .nav-link {
                background: $lightgray;
                padding: 8px 10px;
                border: none;
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;

                .innertitle {
                    font-size: 18px;
                    color: #b2b2b2;
                    font-weight: $fontWeightMedium;
                    text-align: center;

                    span {
                        display: block;

                        span {
                            display: inline-block;
                            border: solid 1px $white;
                            border-radius: 100%;
                            font-size: 14px;
                            font-weight: bold;
                            margin: 0 0 10px;
                            width: 25px;
                            height: 25px;
                            line-height: 27px;
                            color: #b2b2b2;
                            background-color: $white;
                        }
                    }
                }
            }

            .nav-link.active {
                border: solid 1px #000;
                border-bottom: none;
                background-color: $white;

                .innertitle {
                    color: #000;

                    span {
                        span {
                            border-color: #000;
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    .tab-content {
        padding: 50px 0 0;
    }

    .recuritstatus_box {
        background-color: #f4f6f9;
        border: solid 1px $miduumgrey;
        border-radius: 8px;
        text-align: center;
        padding: 30px 30px 20px;
        margin: 50px 0 0px;
        position: relative;

        img {
            width: 100px;
            height: 100px;
            border-radius: 100%;
        }

        h3 {
            margin: 10px 0 5px;
            font-size: 24px;
            font-weight: $fontWeightRegular;
            color: $drakgray;
        }

        p {
            color: $grey;
            margin: 0px 0 5px;
            font-size: 14px;
            font-weight: $fontWeightRegular;
        }

        .btn_iconss {
            text-align: center;
            width: 100%;
            margin: 0 0 22px;

            > div {
                width: auto;
                display: inline-block;
                background: $blue;
                margin: 0 4px;
                padding: 10px 22px;
                border-radius: 30px;
                color: $white;
                line-height: initial;
                font-size: 18px;
                font-family: $NanumSquare;
                font-weight: $fontWeightMedium;
            }

            .select_confmd {
                background-color: $red;
                display: none;
            }
        }

        .recuritstatus_detailinfo {
            text-align: left;

            p {
                font-size: 16px;
                color: $grey;
                overflow: hidden;
                height: 20px;

                span {
                    color: $drakgray;
                    font-weight: 600;
                }
            }
        }

        .btn_layout2.bg_grey {
            display: none;
        }
    }

    .influe_selected {
        border: solid 2px #66a0ee;

        .btn_iconss {
            .select_confmd {
                display: inline-block;
            }
        }

        .btn_layout2.bg_skyblue,
        .btn_layout2.bg_skyblue:hover {
            background: $blue;
            border-color: $blue;
            display: none;
        }

        .btn_layout2.bg_grey,
        .btn_layout2.bg_grey:hover {
            background: #cbcbcb;
            border-color: #cbcbcb;
            display: inline-block;
            color: $white;
        }
    }

    .posting_status {
        .modal-body {
            padding: 70px 20px;
        }

        p {
            font-size: 18px;
            color: $drakgray;
            margin: 0 0 50px;
            font-weight: $fontWeightRegular;

            span {
                font-weight: $fontWeightbold;
            }
        }

        button {
            margin: 0 5px;
            padding: 20px;
            width: 200px;
        }
    }
}

.product_shipping {
    table {
        tr {
            td:nth-child(7),
            td:nth-child(6) {
                padding: 0;
            }

            td {
                input,
                select {
                    border-radius: 5px;
                    padding: 20px 10px;
                    font-size: 16px;
                    border: none;
                    text-align: center;
                }

                select {
                    background: url("../images/select_option_icon.png") no-repeat center right 15px;
                    text-align: left;
                }
            }
        }
    }
}

.influ_posting_status {
    border: solid 1px #cccccc;
    padding: 20px;
    border-radius: 8px;
    margin: 0 0 50px;

    .date {
        text-align: right;
        margin: 0 0 5px;
        font-size: 12px;
        font-weight: $fontWeightLight;
        color: $grey;
    }

    .insta_user_info {
        margin: 0 0 20px;

        img {
            width: 100px;
            height: 100px;
            border-radius: 100%;
        }

        > div {
            width: 60%;
            float: right;
            padding: 30px 5px;

            h3 {
                font-size: 20px;
                color: $drakgray;
                font-weight: $fontWeightMedium;
                margin: 0;
            }

            p {
                font-size: 12px;
                color: $grey;
                font-weight: $fontWeightRegular;
                margin: 0;
            }
        }
    }

    .influ_posting_like_comt {
        ul {
            float: left;
        }
    }

    .recuritstatus_detailinfo {
        .btn_evalute {
            background: #89b0fd;
            color: $white;
        }

        .btn_retrival_complt {
            background: $white;
            color: $grey;
            border-color: $grey;
        }

        .btn_assmt_complt {
            background: $grey;
            color: $white;
            border-color: $grey;
        }

        .btn_delivery_complt {
            color: $blue;
            border-color: $blue;
        }
    }
}

.influ_posting_like_comt {
    img {
        width: 100%;
    }

    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: right;

        li {
            display: inline-block;
            padding: 20px 0px 20px 30px;
            margin-left: 16px;
            line-height: initial;
        }

        li:first-child {
            background: url("../images/heart_icon.png") no-repeat center left;
        }

        li:last-child {
            background: url("../images/comment_icon.png") no-repeat center left;
        }
    }
}

.influencer_profile {
    h6 {
        font-size: 18px;
        color: #000;
        font-weight: $fontWeightbold;
        margin: 0 0 20px;
        line-height: initial;
    }

    .comn_profl_lyout {
        border: solid 1px $miduumgrey;
        border-radius: 20px;
        padding: 30px;

        p {
            font-size: 16px;
            color: $grey;
            font-weight: $fontWeightRegular;
            margin-bottom: 10px;

            span {
                font-weight: $fontWeightMedium;
                color: $drakgray;
            }
        }
    }

    .ai_box {
        background-color: #efefef;
        padding: 16px 10px;
        text-align: center;
        border-radius: 14px;

        p {
            margin: 0;
            font-weight: $fontWeightRegular;
            color: #333333;
            span {
                color: $blue;
                font-weight: $fontWeightMedium;
            }
        }
    }

    .influ_prfl_box {
        margin: 0 0 80px;
    }

    .influ_prfl_box1 {
        .user_pic {
            text-align: center;
            margin-bottom: 30px;

            img {
                width: 110px;
                height: 110px;
                border-radius: 100%;
            }
        }

        .ai_graph {
            padding: 85px 60px;
            position: relative;

            img {
                width: 100%;
            }

            .radarchart_percentage {
                position: absolute;
                right: 10%;
                text-align: center;
                top: 40%;

                p {
                    font-size: 18px;
                    font-weight: bold;
                    color: #000;
                    margin: 0 0px;
                }

                h4 {
                    color: $blue;
                    font-size: 46px;
                    font-weight: 900;
                }
            }
        }
    }

    .influ_prfl_box2 {
        .comn_profl_lyout {
            padding: 30px 15px;

            img {
                width: 80%;
                margin: 0 0 24px;
            }

            p {
                margin: 0;
            }
        }

        .business_account {
            position: relative;

            border-radius: 20px;
            padding: 10px 0;
            border: solid 1px #ebebeb;
            text-align: center;

            .overlay {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(229, 229, 229, 0.8);
                text-align: center;
                border-radius: 20px;
                overflow: hidden;
                padding: 95px 0;
                h1 {
                    font-size: 60px;
                    font-weight: $fontWeightbold;
                    color: #000;
                    text-transform: uppercase;
                }
            }

            .row > div:last-child h1 {
                border: none;
            }
            .comn_profl_lyout {
                border: none;

                h4 {
                    font-size: 16px;
                    color: #333;
                    font-weight: $fontWeightMedium;
                    margin: 0;
                }

                h1 {
                    border-right: solid 1px #ebebeb;
                    font-size: 60px;
                    font-weight: $fontWeightbold;
                    color: $blue;
                    padding: 36px 0 21px;
                    span {
                        font-size: 44px;
                        color: $blue;
                    }
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }

    .influ_prfl_box3 {
        .comn_profl_lyout {
            padding-bottom: 0;
        }
    }

    .btn_layout {
        margin: 0 10px;
    }
}

.dwnload_exl,
.dwnload_exl:hover {
    background-color: $white;
    border: solid 1px #6599ff;
    color: #6599ff;
    padding: 15px 20px 15px 50px;
    background: url("../images/download_exl_icon.png") no-repeat center left 20px;
    line-height: initial;
}

.btn_layout2,
.btn_layout2:hover {
    width: 100%;
    background: transparent;
    color: $blue;
    border: solid 1px $blue;
    padding: 15px 5px;
    font-size: 16px;
    font-weight: 600;
}

.btn_layout2.bg_skyblue,
.btn_layout2.bg_skyblue:hover {
    background-color: $blue;
    color: $white;
}

.disclosure_detls {
    h4 {
        font-size: 26px;
        font-weight: $fontWeightbold;
        color: $drakgray;
    }

    p {
        font-size: 18px;
        font-weight: $fontWeightbold;
        color: $grey;
        margin: 0 0 40px;
    }

    .disclosure_detls_box {
        margin: 0 0 30px;

        h6 {
            font-size: 22px;
            font-weight: $fontWeightbold;
            color: $drakgray;
        }

        ul {
            list-style: decimal;

            li {
                font-size: 16px;
                color: $drakgray;
                margin: 0 0 10px;
                line-height: 28px;

                ul {
                    list-style: lower-roman;
                    margin-top: 10px;

                    ul {
                        list-style: disc;

                        a {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
}

.notice_impo {
    color: $red;
    font-size: 16px;
}

.star {
    display: inline-block !important;
    font-size: 16px !important;
}

#product_recall {
    p {
        font-size: 18px;
    }
    button {
        line-height: initial !important;
    }
}

.recuritstatus_detail1 {
    .recuritstatus_box {
        background-color: #fff;
        padding: 15px 10px 15px;
        .recuritstatus_profile_info {
            padding: 0 20px;
            text-align: left;
            h3 {
                margin-top: 20px !important;
                span {
                    font-size: 18px;
                }
            }
            img {
                width: 100% !important;
                height: auto !important;
            }
        }
        .btn_iconss {
            text-align: center;
            width: auto;
            margin: 0;
            position: absolute;
            top: -30px;
            right: 0;

            > div {
                font-size: 18px;
                font-weight: $fontWeightRegular;

                width: 58px;
                height: 58px;
                border-radius: 100%;
                margin: 0 10px 0px 0;
                position: relative;
                vertical-align: top;
                padding: 0;
                span {
                    color: $blue;
                    opacity: 1;
                    font-size: 14px;
                }
            }

            .percent {
                padding: 17px 2px;
                background-color: #e0ecfc;
                color: $blue;
            }
            .select_confmd {
                font-size: 16px;
                padding-top: 12px;
                text-align: center;
            }
        }
        .recuritstatus_detailinfo {
            > div.row {
                padding: 0 20px;

                .btn_layout2,
                .btn_layout2:hover {
                    padding: 10px 5px;
                    border-radius: 30px;
                    line-height: initial;
                }
            }
        }

        .ai_tech {
            background: #f7f7f7;
            border-radius: 8px;
            padding: 10px;
            margin: 10px 0 10px;
        }
    }

    .recuritstatus_box.influe_selected {
        background-color: #f4f6f9;
        .btn_iconss {
            .percent {
                background-color: $blue;
                color: #fff;
                span {
                    color: #fff;
                }
            }
        }
        .ai_tech {
            background: #e4eaf4;
        }
    }
}

#posting_status {
    .btn_layout {
        margin: 0 5px;
    }
}
#product_recall,
.campaign_modal {
    .btn_layout {
        padding: 20px 60px;
        font-size: 22px;
    }
    p {
        font-size: 22px;
    }
}

.extra_text {
    color: $red;
    font-size: 14px;
    font-weight: $fontWeightMedium;
}
.landing_page_btn {
    display: none;
}

/*  Responsive style  */

$breakpoint-minDesktop: 1580px;

@media screen and (max-width: $breakpoint-minDesktop) {
    #posting_status {
        .btn_layout {
            padding: 15px 60px;
            margin: 0 5px;
        }
    }
    .main_header {
        z-index: 999;
        position: relative;
    }
    .hm_banner .floding_effect {
        width: 9%;
        z-index: 99;
    }

    .header_logo {
        width: 15% !important;

        img {
            width: 100%;
            height: auto;
        }
    }

    .menu_bar ul.navbar-nav {
        z-index: 9999;
        position: relative;
    }

    .menu_bar ul .after_login {
        width: 50%;
    }

    .tab_panel > .container,
    .register_form > .container {
        width: 55%;
    }

    .mycampaign {
        .completed_campaign {
            table {
                td:nth-child(3) {
                    width: 210px;
                }

                td:nth-child(4) {
                    width: 255px;
                }
            }
        }
    }

    .register_campaign_tab .tab-content .common_review .container,
    .edit_reeview .container {
        width: 100%;
    }

    .check_campaign_request {
        .campaign_request_detail {
            .container {
                width: 100%;
            }
        }
    }

    .findresults {
        padding: 40px 20px;
    }
    .edit_reeview .clander_input input {
        width: 174px;
        margin: 0 0 0 10px;
    }

    .form-check-label {
        font-size: 14px;
    }
    .edit_reeview .container .form-label {
        font-size: 14px;
    }

    .landing_page .hm_banner .banner_body .banner_text .container {
        width: 70%;
    }
    .landing_page .hm_banner .banner_body .banner_text h1 {
        font-size: 56px;
    }
}

@media screen and (max-width: 1024px) {
    .hm_banner .floding_effect {
        width: 8%;
        z-index: 9;
    }

    .header_logo {
        width: 30% !important;

        img {
            width: 100%;
            height: auto;
        }
    }

    .menu_bar ul .after_login {
        width: 50%;
    }

    .landing_page .hm_banner .banner_body .banner_text .container {
        width: 70%;
    }
    .landing_page .hm_banner .banner_body .banner_text h1 {
        font-size: 36px;
    }
}

@media screen and (max-width: 991px) {
    .landing_page_btn {
        display: inline-block;
    }

    .hm_banner {
        .floding_effect {
            display: none;
        }

        .banner_body {
            .banner_text {
                margin-top: 100px;

                h1 {
                    margin-bottom: 30px;
                    font-size: 30px;
                }
            }
        }
    }

    .login_modal {
        .modal-body {
            padding: 50px 30px;
        }
    }

    .tab_panel > .container {
        width: 70% !important;
    }

    .register_form > .container,
    .tab_panel > .container,
    .common_layout .container {
        width: 95%;
    }

    .inner_heading {
        font-size: 38px;
        margin: 0 0 50px;
    }

    .mtb190 {
        margin-top: 50px;
        margin-bottom: 80px;
    }

    .footer {
        padding: 40px 0;

        .box_left {
            order: 3;
        }
    }

    .benefit_pts {
        .benefit_pts_dtls {
            margin-top: 50px;

            .text_info {
                margin-top: 0;

                h1 {
                    font-size: 26px;
                    line-height: 40px;

                    img.icon_img {
                        width: 70px;
                    }

                    img.icon_img2 {
                        left: 100px;
                        bottom: 0;
                    }

                    img.icon_img3 {
                        left: 130px;
                    }

                    img.icon_img5 {
                        top: 0;
                    }
                }

                p {
                    font-size: 20px;
                    margin: 0 0 20px;
                }
            }
        }
    }

    .user_modal {
        .modal-content {
            padding: 30px 20px;

            .modal-header {
                h5 {
                    font-size: 22px;
                    margin: 0px 0 10px;
                }
            }

            .modal-body {
                padding: 0;

                .notice {
                    padding: 15px;
                }

                .row {
                    > div:nth-child(2) {
                        margin: 0 0 10px;
                    }
                }

                .mb-5 {
                    margin-bottom: 1.5rem !important;
                }

                .form-check-inline {
                    margin-right: 5px;
                }
            }
        }
    }
    .landing_page .hm_banner .banner_body .banner_text .row {
        width: 46%;
    }
    .landing_page .hm_banner .banner_body .banner_text h1 {
        font-size: 14px !important;
    }
    .landing_page .hm_banner .banner_body .banner_text .row {
        .app_btns {
            width: 50%;
            padding: 0 5px;
            a {
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .main_header {
        padding: 10px 0;
        display: block;

        .header_logo {
            width: 30% !important;
            float: left;
        }

        .navbar-toggler {
            float: right;
        }

        .navbar-collapse.show {
            background: #fff;
            padding: 20px;
            float: left;
            border-radius: 8px;

            .nav-item {
                border-bottom: solid 1px #bebebe;
                padding: 5px 14px;
            }
        }

        .menu_bar {
            ul {
                li:first-child a::after {
                    display: none;
                }

                .after_login {
                    text-align: left;
                    display: block;
                    width: 100%;
                    float: left;
                    margin-top: 0px;
                }
            }
        }
    }

    .header {
        padding: 5px 0px 15px;
    }

    .header .navbar {
        justify-content: space-between !important;
    }

    .hm_banner {
        .banner_body {
            .banner_text {
                margin-bottom: 0px;
                margin-top: auto;

                h1 {
                    font-size: 16px;
                    margin-bottom: 5px;
                }

                .btn_layout {
                    padding: 8px 30px;
                    font-size: 14px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .benefit_pts .benefit_pts_dtls:last-child,
    .benefit_pts .benefit_pts_dtls:nth-child(4),
    .benefit_pts .benefit_pts_dtls:first-child {
        margin-top: 10px;
    }
    .benefit_pts .benefit_pts_dtls:nth-child(3) {
        margin-top: 0;
    }

    .benefit_pts .benefit_pts_dtls:nth-child(4) .text_info,
    .benefit_pts .benefit_pts_dtls:nth-child(5) .text_info {
        margin-top: 10px;
    }

    .benefit_pts {
        padding: 20px 0;

        .benefit_pts_dtls {
            margin-top: 20px;
            flex-direction: column;

            img {
                width: 100%;
            }

            .text_info {
                order: -1;

                p {
                    font-size: 14px;
                    margin: 0 0 10px;
                }

                h1 {
                    font-size: 18px;
                    line-height: 24px;

                    img.icon_img {
                        display: none;
                    }
                }
            }
        }
    }

    .campaign_process {
        padding: 30px 0 20px;

        .heading {
            font-size: 18px;
            margin: 0 0 10px;
            line-height: 24px;
        }

        .campaign_process_pts {
            .row {
                flex-direction: column;
            }

            .number {
                margin: 0 0 15px;
            }

            img {
                width: 20%;
                float: left;
            }

            h4 {
                margin: 0 0 5px;
                width: 70%;
                float: right;
                font-size: 16px;
            }

            p {
                width: 70%;
                float: right;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    footer {
        padding: 20px 0;
        .footer_logo {
            img {
                width: 40%;
            }
        }
        h5 {
            font-size: 16px;
        }
        ul.term_policy {
            margin-top: 10px;
        }

        ul.social_links {
            text-align: left;
            margin: 10px 0;

            li {
                a {
                    padding: 0 15px 0 0;
                    font-size: 12px;
                }

                img {
                    width: 25px;
                }
            }
        }
        .box_right {
            margin: 10px 0;
        }
    }
    footer p,
    footer a,
    footer .mail_btn,
    footer .mail_btn:hover,
    footer .mail_btn:active {
        line-height: 22px;
        font-size: 12px;
    }

    .scroll__container {
        display: none;
        right: 16px;
        bottom: 85px;
        img {
            width: 40px;
        }
    }

    .hr_line {
        height: 65px;
    }

    .inner_heading {
        font-size: 24px;
        margin: 0 0 20px;
    }

    .mycampaign_detail {
        p.simple_text {
            margin: 0 0 20px !important;
        }
    }

    .mb-5 {
        margin-bottom: 20px !important;
    }

    .review_small_width {
        .container {
            width: 100%;
        }

        .review_category {
            margin-bottom: 20px;

            .form-check-inline {
                margin-bottom: 0px !important;
            }
        }
    }

    .form-check-inline {
        margin-right: 10px;
    }

    .btn_layout {
        padding: 12px 30px;
    }

    .mycampaign {
        .btn_layout {
            font-size: 18px;
        }
    }

    .findresults {
        padding: 20px;
        margin: 0 0 10px;
    }

    .campaign_cost .container,
    .register_campaign_tab.tab_panel .container {
        width: 100% !important;
    }

    .campaign_cost {
        .row {
            padding: 15px 10px 10px;

            .text-end {
                text-align: left !important;
            }
        }
    }

    .register_campaign_tab.tab_panel {
        .nav-fill {
            .nav-item {
                .nav-link {
                    font-size: 14px;
                }
            }
        }

        .tab-content {
            .common_review {
                margin-top: 0;

                .container {
                    .mb-7 {
                        margin-bottom: 20px;
                    }

                    .gender_age {
                        > .row {
                            .width_10 {
                                width: 100%;
                                text-align: left !important;
                            }
                        }
                    }
                }

                .form-label {
                    margin-bottom: 10px;
                    font-size: 14px;

                    span.numbers {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .mtb190 {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .mt-5 {
        margin-top: 20px !important;
    }

    .clander_input {
        input {
            margin-bottom: 5px;
        }
    }

    .campaign_request_detail {
        .container {
            .row {
                > div {
                    text-align: left !important;
                }
            }
        }
    }

    .login_modal .btn_layout {
        padding: 16px;
    }
    .tab_panel > .container {
        width: 90% !important;
    }
    .tab_panel .nav-fill .nav-item .nav-link {
        font-size: 16px;
    }
    .col-form-label,
    .form-label {
        padding-left: 15px;
    }
    .register_form .term_condition {
        padding: 10px 10px 0;
    }
    .term_condition {
        .form-check-label {
            display: inline;
        }
    }
    .faq_panel .faq_details .accordion-item {
        padding: 0 30px;
    }
    .faq_panel .accordion button {
        font-size: 16px;
    }
    .faq_panel .faq_details h3 {
        font-size: 20px;
    }
    .faq_panel .faq_details {
        margin: 0 0 30px;
    }
    .mycampaign .nav-tabs .nav-item .nav-link {
        font-size: 14px;
        padding: 15px 40px;
        margin-top: 5px;
    }

    .mycampaign {
        .ongoing_campaign {
            table {
                width: 600px;
                th {
                    width: 100px !important;
                    padding: 10px;
                }
                td {
                    width: 100px !important;
                    padding: 10px;
                }
            }

            .countdown_box {
                > div {
                    width: 33% !important;
                    padding: 5px;
                }
                > div:last-child {
                    width: 100% !important;
                }
            }
        }
    }

    .mycampaign .countdown .countdown_info p span.date_time {
        position: relative;
        top: -20px;
        font-size: 18px;
    }

    .mycampaign .completed_campaign .countdown .width_50 {
        width: 50% !important;
        padding: 5px;
    }
    .mycampaign .completed_campaign .graph .graph_details .average_data {
        margin: 0 0 10px;
    }
    .mycampaign .form-select {
        font-size: 16px;
        padding: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
    .mycampaign.faq_panel.mtb190 {
        .col-sm-2.float-end {
            width: 100%;
        }
    }
    .campaign_pymt_done .container {
        width: 90%;
    }
}
